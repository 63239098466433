import Header from "components/header/Header";
import "./desktop.scss";
import WhatMakeUsDifferent from "./components/WhatMakeUsDifferent/WhatMakeUsDifferent";
import OurImpact from "./components/OurImpact/OurImpact";
import OurValues from "./components/OurValues/OurValues";
import OurTeam from "./components/OurTeam/OurTeam";
import Footer from "components/footer";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import OurJourneyTimeline from "./components/OurJourneyTimeline/OurJourneyTimeline";
import OurStory from "./components/OurStory/OurStory";
const About = () => {
  const dataSection = [
    {
      id: 1,
      title: "10+",
      description: "Partner Swim Schools",
    },
    {
      id: 2,
      title: "5,000+",
      description: "Active Students Managed",
    },
    {
      id: 3,
      title: "300+",
      description: "Hours Saved Per Week",
    },
    {
      id: 4,
      title: "30+",
      description: "Features Launched",
    },
    {
      id: 5,
      title: "Countless",
      description: "Coffee Consumed During Development",
    },
  ];
  return (
    <div className="aboutPage">
      <Header autoScrolled />
      <div className="aboutPage-content">
        <div className="aboutPage-content-header">
          <SlideUpOnScroll>
            <div className="aboutPage-content-header-title">
              <h1>Making Waves in Swim School Management</h1>
              <p>
                Every splash matters. At SwimSync, we're more than just software
                – we're parents and tech enthusiasts who understand both sides
                of the swim school experience. Born from poolside frustrations
                and powered by cutting-edge technology, we're transforming how
                swim schools operate, one line of code at a time.
              </p>
            </div>
          </SlideUpOnScroll>
          <div className="ocean">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </div>
        <OurStory />
        <WhatMakeUsDifferent />
        <OurImpact />
        <div className="ourMission">
          <SlideUpOnScroll>
            <div className="ourMission-content">
              <div className="ourMission-content-item">
                <div className="ourMission-content-item-title">
                  <div className="icon"></div>
                  Our Mission
                </div>
                <div className="ourMission-content-item-description">
                  <p>
                    To revolutionize swim school operations through intuitive
                    technology,
                    <br /> enabling swim schools to focus on what truly matters
                    – creating
                    <br /> confident swimmers
                  </p>
                </div>
              </div>
              <div className="ourMission-content-item">
                <div className="ourMission-content-item-title">
                  <div className="icon"></div>
                  Our Vision
                </div>
                <div className="ourMission-content-item-description">
                  <p>
                    To be the catalyst that transforms swim education globally
                    by making
                    <br /> world-class management tools accessible to every swim
                    school,
                    <br /> regardless of size
                  </p>
                </div>
              </div>
            </div>
          </SlideUpOnScroll>
        </div>
        <OurValues />
        <div className="data-section">
          <SlideUpOnScroll>
            <div className="data-section-content">
              {dataSection.map((item) => (
                <div className="data-section-content-item" key={item.id}>
                  <div className="data-section-content-item-title">
                    {item.title}
                  </div>
                  <div className="data-section-content-item-description">
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          </SlideUpOnScroll>
        </div>
        <OurTeam />
        <OurJourneyTimeline />
        <div className="joinTheRevolution">
          <SlideUpOnScroll>
            <div className="joinTheRevolution-content">
              <div className="joinTheRevolution-content-title">
                Join the Revolution
              </div>
              <div className="joinTheRevolution-content-description">
                We're looking for forward-thinking swim schools ready to
                transform their operations. Whether you're teaching 20 students
                or 2,000, SwimSync grows with you.
              </div>
            </div>
          </SlideUpOnScroll>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default About;
