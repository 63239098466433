import { useState } from "react";
import "./desktop.scss";
const WhatMakeUsDifferent = () => {
  const [activeItemId, setActiveItemId] = useState(1);
  const data = [
    {
      id: 1,
      image: "/images/aboutUs/whatMakeUsDifferent/different1.png",
      title: "Built by Tech Parents Who Get It",
      content:
        "We've sat in those pool chairs, made those frustrating phone calls, and lost those progress reports. Every feature is designed with real experiences in mind, both from a parent's and operator's perspective",
    },
    {
      id: 2,
      image: "/images/aboutUs/whatMakeUsDifferent/different2.png",
      title: "Technology That Makes Sense",
      content:
        "No more digital stone age. We've brought sophisticated tech solutions to swim schools while keeping it simple and intuitive. From smart scheduling to automated progress tracking, we're making technology work for you, not against you",
    },
    {
      id: 3,
      image: "/images/aboutUs/whatMakeUsDifferent/different3.png",
      title: "Growing Together",
      content:
        "We built SwimSync through genuine partnerships with swim schools. Every feature, every update, and every improvement comes from real feedback and real needs. As your needs evolve, so do we",
    },
  ];
  return (
    <div className="whatMakeUsDifferent">
      <h1>What Makes Us Different</h1>
      <div className="whatMakeUsDifferent-content">
        {data.map((item) => (
          <div
            className={`whatMakeUsDifferent-content-item ${
              activeItemId === item.id ? "active" : ""
            }`}
            key={item.id}
            onMouseEnter={() => setActiveItemId(item.id)}
          >
            {activeItemId !== item.id && <div className="overlay"></div>}

            <img
              src={item.image}
              alt="different1"
              className="whatMakeUsDifferent-content-item--image"
            />
            <div
              className={`whatMakeUsDifferent-content-item--title-overlay ${
                activeItemId === item.id ? "hidden" : ""
              }`}
            >
              {item.title}
            </div>
            <div
              className={`whatMakeUsDifferent-content-item--text ${
                activeItemId !== item.id ? "hidden" : ""
              }`}
            >
              <div className="whatMakeUsDifferent-content-item--title">
                {item.title}
              </div>
              <div className="whatMakeUsDifferent-content-item--content">
                {item.content}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatMakeUsDifferent;
