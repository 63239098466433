import "./desktop.scss";

import { memo } from "react";

const notice = () => {
  return (
    <>
      <div className="interested_notice">
        <div className="text">
          Ready to make managing your swimming school easier than a cannonball
          into the deep end? Dive into this form and help us understand your
          needs! Your insights are the lifebuoy we need to help you swim
          smoothly.
        </div>

        <div className="text2">
          <span className="bold">Let’s make a splash!</span>
           Fill in the form below!
        </div>
      </div>
    </>
  );
};

export default memo(notice);
