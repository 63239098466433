import Slider from "react-slick";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import "./desktop.scss";

const Introduction = () => {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 2.65,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const imageData: string[] = [
    "/images/introductionMP/image5.png",
    "/images/introductionMP/image6.png",
    "/images/introductionMP/image7.png",
    "/images/introductionMP/image8.png",
  ];

  const listFeatures = [
    {
      id: 1,
      title: "Dynamic Dashboards",
      description: "Real-time overview of key performance indicators",
      subTitle: "Dynamic Dashboards",
      subDescription:
        "Get a bird's-eye view of your swim school's performance:",
      subList: [
        "Customizable dashboard with drag-and-drop widgets",
        "Real-time updates on key metrics like revenue, attendance, and utilization",
        "Interactive charts and graphs for visual data representation",
        "Ability to drill down into specific data points for detailed analysis",
        "Set custom alerts for important threshold breaches",
      ],
      icon: "/svgs/introduction/clipboard.svg",
    },
    {
      id: 2,
      title: "Student Performance Tracking",
      description: "Monitor individual and group progress over time",
      subTitle: "Student Performance Tracking",
      subDescription: "Monitor and analyze student progress effectively:",
      subList: [
        "Track individual student skill development over time",
        "Generate group performance reports by class, level, or age group",
        "Identify students who may need additional support or are ready for advancement",
        "Visualize skill acquisition rates and milestone achievements",
        "Compare performance across different programs or locations",
      ],
      icon: "/svgs/introduction/calendar-swim.svg",
    },
    {
      id: 3,
      title: "Financial Reporting",
      description: "Comprehensive financial analysis and forecasting",
      subTitle: "Financial Reporting",
      subDescription:
        "Gain deep insights into your swim school's financial health:",
      subList: [
        "Generate profit and loss statements, balance sheets, and cash flow reports",
        "Analyze revenue trends by program, location, or time period",
        "Track expenses and identify cost-saving opportunities",
        "Forecast future revenue based on enrollment trends and historical data",
        "Compare financial performance across multiple locations or time periods",
      ],
      icon: "/svgs/introduction/chart-swim.svg",
    },
    {
      id: 4,
      title: "Attendance and Utilization",
      description: "Track attendance trends and pool utilization",
      subTitle: "Attendance and Utilization",
      subDescription: "Optimize your class schedules and pool usage:",
      subList: [
        "Track attendance rates by class, instructor, or program",
        "Visualize peak hours and identify underutilized time slots",
        "Monitor no-show rates and analyze patterns",
        "Generate pool utilization heat maps to maximize efficiency",
        "Set capacity alerts to maintain optimal student-to-instructor ratios",
      ],
      icon: "/svgs/introduction/messages.svg",
    },
    {
      id: 5,
      title: "Instructor Performance",
      description: "Evaluate and optimize instructor effectiveness",
      subTitle: "Instructor Performance",
      subDescription: "Evaluate and support your teaching staff:",
      subList: [
        "Track key performance indicators like student retention and progress rates",
        "Analyze student feedback scores and comments",
        "Monitor instructor attendance and punctuality",
        "Compare performance across different instructors or programs",
        "Identify top performers and areas for professional development",
      ],
      icon: "/svgs/introduction/shield-dollar.svg",
    },
    {
      id: 6,
      title: "Custom Report Builder",
      description: "Create tailored reports for specific needs",
      subTitle: "Custom Report Builder",
      subDescription: "Create tailored reports to meet your specific needs:",
      subList: [
        "Drag-and-drop interface for building custom reports",
        "Choose from a wide range of data points and metrics",
        "Apply custom filters and parameters",
        "Save report templates for future use",
        "Schedule automated report generation and distribution",
      ],
      icon: "/svgs/introduction/block-waves.svg",
    },
    {
      id: 7,
      title: "Enrollment Analytics",
      description: "Analyze registration patterns and class fill rates",
      subTitle: "Enrollment Analytics",
      subDescription: "Optimize your class offerings and marketing efforts:",
      subList: [
        "Analyze registration patterns and trends over time",
        "Track class fill rates and waitlist demand",
        "Identify popular programs and potential new offerings",
        "Monitor student retention and churn rates",
        "Analyze the effectiveness of promotions and discounts",
      ],
      icon: "/svgs/introduction/shield-dollar.svg",
    },
    {
      id: 8,
      title: "Revenue Analysis",
      description: "Break down revenue streams and identify opportunities",
      subTitle: "Revenue Analysis",
      subDescription: "Maximize your swim school's financial performance:",
      subList: [
        "Break down revenue streams by program, class type, or student age group",
        "Analyze average revenue per student and lifetime value",
        "Identify high-performing programs and potential areas for growth",
        "Track the impact of pricing changes and promotions on revenue",
        "Forecast future revenue based on enrollment trends and seasonal pattern",
      ],
      icon: "/svgs/introduction/block-waves.svg",
    },
    {
      id: 9,
      title: "Exportable Reports",
      description: "Generate and export reports in multiple formats",
      subTitle: "Exportable Reports",
      subDescription: "Share insights easily with stakeholders:",
      subList: [
        "Generate professional-looking reports with custom branding",
        "Export reports in multiple formats (PDF, Excel, CSV)",
        "Schedule automated report delivery via email",
        "Create shareable links for online report viewing",
        "Set up role-based access controls for sensitive financial data",
      ],
      icon: "/svgs/introduction/shield-dollar.svg",
    },
  ];

  return (
    <>
      <div className="ReportNAnalytics-introduction">
        <SlideUpOnScroll>
          <div className="ReportNAnalytics-introduction--header">
            <div className="ReportNAnalytics-introduction--header--description">
              Dive deep into your swim school's performance with Swim Sync's
              powerful Reporting and Analytics tools. Our comprehensive suite of
              reports and real-time dashboards provide you with actionable
              insights to make informed decisions, optimize operations, and
              drive growth.
            </div>
          </div>
        </SlideUpOnScroll>
        <SlideUpOnScroll>
          <div className="ReportNAnalytics-introduction--slider-wrapper">
            <Slider {...settings}>
              {imageData.map((item, key) => (
                <div
                  key={key}
                  className="ReportNAnalytics-introduction--slider-wrapper--image"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </SlideUpOnScroll>
      </div>
      <div className="ReportNAnalytics-introduction--featuresContainer">
        <div className="ReportNAnalytics-introduction--featuresWrapper">
          {listFeatures.map((item, key) => (
            <div
              key={key}
              className="ReportNAnalytics-introduction--featuresItem"
            >
              <div className="ReportNAnalytics-introduction--featuresItemMain">
                <div className="ReportNAnalytics-introduction--featuresItemLeft">
                  <img
                    src={item.icon}
                    alt=""
                    className="ReportNAnalytics-introduction--featuresItemLeft--icon"
                  />
                  <div className="ReportNAnalytics-introduction--featuresItemLeft--title">
                    {item.title}
                  </div>
                  <div className="ReportNAnalytics-introduction--featuresItemLeft--description">
                    {item.description}
                  </div>
                </div>
                <div className="ReportNAnalytics-introduction--featuresItemRight">
                  <p className="ReportNAnalytics-introduction--featuresItemRight--subTitle">
                    {item.subTitle}
                  </p>
                  <p className="ReportNAnalytics-introduction--featuresItemRight--subDescription">
                    {item.subDescription}
                  </p>
                  <ul>
                    {item.subList.map((li, liIndex) => {
                      return <li key={liIndex}>{li}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Introduction;
