import "./desktop.scss";
const OurValues = () => {
  return (
    <div className="OurValues">
      <div className="OurValues-grid">
        <div className="OurValues-item OurValues-item-first">
          <h1>Our Values</h1>
          <div className="bg-img-ellipse">
            <img src="/images/aboutUs/ourValues/Ellipse.png" alt="" />
          </div>

          <div className="ocean">
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </div>
        <div className="OurValues-item OurValues-item-second">
          <img
            src="/images/aboutUs/ourValues/item2.png"
            className="bg-img"
            alt=""
          />
          <img
            src="/images/aboutUs/ourValues/item2-1.png"
            className="img-icon"
            alt=""
          />
          <div className="content">
            <h1>Partnership First</h1>
            <div className="description">
              We succeed when swim schools succeed. Every feature, update, and
              solution is developed in close collaboration with our swim school
              partners
            </div>
          </div>
        </div>

        <div className="OurValues-item OurValues-item-third">
          <div className="item-wrapper">
            <img src="/svgs/aboutUs/innovation.svg" alt="" />
            <div className="title">Innovation Through Understanding</div>
            <div className="description">
              We don't just build technology – we observe, listen, and
              understand the real challenges faced by swim schools, staff, and
              families
            </div>
          </div>
          <div className="item-wrapper">
            <img src="/svgs/aboutUs/simplicity.svg" alt="" />
            <div className="title">Simplicity by Design</div>
            <div className="description">
              Complex problems deserve simple solutions. We turn swimming school
              chaos into streamlined efficiency
            </div>
          </div>
        </div>
        <div className="OurValues-item OurValues-item-third">
          <div className="item-wrapper">
            <img src="/svgs/aboutUs/continuous.svg" alt="" />
            <div className="title">Continuous Growth</div>
            <div className="description">
              We don't just build technology – we observe, listen, and
              understand the real challenges faced by swim schools, staff, and
              families
            </div>
          </div>
        </div>

        <div className="OurValues-item OurValues-item-fourth">
          <img src="/images/aboutUs/ourValues/item5.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default OurValues;
