import { useRef, useState } from "react";
import "./desktop.scss";

const OurStory = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isScrolledToStart, setIsScrolledToStart] = useState(false);

  const handleWheel = (e: any) => {
    if (!scrollContainerRef.current) return;

    const container = scrollContainerRef.current;
    const isAtEnd =
      container.scrollLeft + container.clientWidth >=
      container.scrollWidth - 50;
    setIsScrolledToEnd(isAtEnd);
    setIsScrolledToStart(container.scrollLeft === 0);
    container.scrollLeft += e.deltaY;
    document.body.style.overflow =
      isScrolledToEnd || isScrolledToStart ? "scroll" : "hidden";
  };

  return (
    <div className="OurStory">
      <h1>Our Story</h1>
      <div
        className="OurStory-content"
        ref={scrollContainerRef}
        onWheel={handleWheel}
        onMouseOver={() => {
          document.documentElement.style.cssText = "scrollbar-gutter: stable";
          document.body.style.overflow = "hidden";
        }}
        onMouseOut={() => {
          document.body.style.overflow = "auto";
          setIsScrolledToEnd(false);
        }}
      >
        <div className="card">
          <div className="text">
            SwimSync was born in the most unexpected of places – not in a
            boardroom, but on the pool deck where three tech-obsessed parents
            (Oscar, Maricar, and Moses) waited during their kids' swimming
            lessons.
          </div>
          <div className="text">
            While watching their little ones learn to swim, they couldn't help
            but notice the swimming school staff juggling multiple outdated
            systems, paper records, and clunky software.
          </div>
        </div>

        <div className="card">
          <div className="text">
            As software engineers who lived for solving complex problems, they
            saw their kids' swim schools struggling with technology that
            belonged in the digital stone age. The final straw? When Maricar had
            to call three times to reschedule a lesson, Oscar lost a term's
            worth of progress reports, and Moses couldn't book a make-up class
            through the existing system.
          </div>
        </div>
        <div className="card-wrapper">
          <div className="description">
            Over coffee after one particularly chaotic lesson, they decided:
          </div>
          <div className="card-custom">
            <div className="title">"We can build something better."</div>
            <div className="text">
              They reached out to their kids' swim schools, and to their
              surprise, found incredibly enthusiastic partners eager for change.
              What followed was months of late-night coding sessions, countless
              hours observing pool operations, and intensive feedback rounds
              with swim school owners and staff.
            </div>
          </div>
        </div>
        <div className="card">
          <div className="text">
            The journey wasn't smooth sailing – with few swim management
            platforms to benchmark against, every feature had to be built from
            the ground up. They practically lived at swim schools, shadowing
            everything from student assessments to staff scheduling. Each line
            of code was written with direct input from the people who would use
            it daily.
          </div>
        </div>
        <div className="card">
          <div className="text">
            Today, after two years of development, testing, and refinement with
            our partner swim schools, SwimSync V2 is ready to make waves in the
            industry. What started as three parents' frustration has evolved
            into a platform revolutionizing swim school operations.
          </div>
        </div>
        <div className="card">
          <div className="text">
            We're not just tech people who built a swim school platform – we're
            parents who understand the importance of swimming education, tech
            enthusiasts who believe in elegant solutions, and partners who are
            committed to helping swim schools thrive in the digital age.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
