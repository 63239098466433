import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const ImageAnimation = () => {
  const [showFirstImage, setShowFirstImage] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstImage((prev) => !prev);
    }, 5000); // change every 5 seconds

    return () => clearInterval(interval); // clean up on unmount
  }, []);
  return (
    <>
      <AnimatePresence>
        {!showFirstImage ? (
          <motion.img
            key="firstImage"
            src="/images/bookDemo/calendar.png"
            alt="Foreground Image"
            initial={{ opacity: 1, scale: 1, y: 40 }}
            animate={{ opacity: 0.8, scale: 0.8, y: -20 }}
            exit={{ opacity: 0.8 }}
            transition={{ duration: 0.5 }}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 0,
              top: "-30px",
            }}
          />
        ) : (
          <motion.img
            key="secondImage"
            src="/images/bookDemo/calendar.png"
            alt="Foreground Image"
            initial={{ opacity: 1, scale: 1, y: 40 }}
            animate={{ opacity: 0.8, scale: 0.8, y: -20 }}
            exit={{ opacity: 0.8 }}
            transition={{ duration: 0.5 }}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 0,
              top: "-30px",
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showFirstImage ? (
          <motion.img
            key="firstImage"
            src="/images/bookDemo/calendar.png"
            alt="Foreground Image"
            initial={{ opacity: 1, scale: 0.8, y: -20 }}
            animate={{ opacity: 1, scale: 1, y: 20 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 1, // In front of the background image
            }}
          />
        ) : (
          <motion.img
            key="secondImage"
            src="/images/bookDemo/calendar.png"
            alt="Foreground Image"
            initial={{ opacity: 1, scale: 0.8, y: -20 }}
            animate={{ opacity: 1, scale: 1, y: 20 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 1, // In front of the background image
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ImageAnimation;
