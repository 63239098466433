import AppModal from "components/AppModal";
import { HiXMark } from "react-icons/hi2";
import "./desktop.scss";
import { useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
}
const LoginModal: React.FC<Props> = ({ open, onClose }) => {
  const [showForStaff, setShowForStaff] = useState(true);

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="loginModal">
        <div className="loginModal-header">
          <div className="loginModal-header__title">
            Dive into Your Swimming Journey!
          </div>
          <div className="loginModal-header__close" onClick={onClose}>
            <HiXMark size={24} />
          </div>
        </div>
        <div className="loginModal-content">
          <div className="email-input">
            <div className="input-label">Email Address</div>
            <input
              className="email"
              type="email"
              placeholder="Enter your email"
            />
            {showForStaff && (
              <span className="input-description">
                Owner, Staff, or Instructor
              </span>
            )}
          </div>
          <div className="login-btn">
            <button>Login</button>
          </div>
          {showForStaff ? (
            <div className="loginModal-footer">
              <div className="footer-text">
                Are you part of the swimming school?
                <div className="login-text">Log in here</div>
              </div>
              <div
                className="footer-description"
                onClick={() => setShowForStaff(false)}
              >
                (Parents or Guardians)
              </div>
            </div>
          ) : (
            <div className="loginModal-footer">
              <div className="footer-text">
                Don’t have an account?
                <div className="login-text">Register here</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
};

export default LoginModal;
