import "./desktop.scss";

const BookDemo = () => {
  return (
    <div className="IntegrationNSecurity-book-demo">
      <div className="IntegrationNSecurity-book-demo--wrapper">
        <div className="IntegrationNSecurity-book-demo--title">
          Ready to enhance your swim school's connectivity and security?
          <br />
          Dive into Swim Sync's integration and security features today
        </div>
        <div className="IntegrationNSecurity-book-demo--buttonWrapper">
          <button
            className="IntegrationNSecurity-book-demo--button"
            onClick={() => {
              // Mở widget khi nhấn nút
              window.Calendly.showPopupWidget(
                "https://calendly.com/swimsync-support/swim-sync-demo-booking"
              );
            }}
          >
            Request a Demo
          </button>
          {/* <button
            className="IntegrationNSecurity-book-demo--button"
            onClick={() => {}}
          >
            Learn About Our Security
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default BookDemo;
