import Header from "components/header/Header";
import "./desktop.scss";
import Footer from "components/footer";

const TermCondition = () => {
  return (
    <div className="TermCondition">
      <Header autoScrolled />
      <div className="TermCondition-wrapper">
        <div className="TermCondition-header-title">
          <img src="/images/shield-tick.png" alt="" />
          Term & Condition
        </div>
        <div className="TermCondition-content">
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                These Swim Sync Terms and Conditions ("T&C") apply to all users
                of the Swim Sync platform, a product of Advancer Global Digital
                Transformation, designed for swimming schools and institutions.
                By accessing or using Swim Sync, you agree to comply with these
                T&C.
              </div>
            </div>
          </div>
          {/* 1. General Terms and Conditions */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              1. General Terms and Conditions
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                These Terms govern your access and use of the Swim Sync
                platform, including the Swim Sync website, mobile app, and
                related services (collectively, the "Service" or "Services"). By
                accessing, registering, or using the Service, you agree to be
                bound by these T&C.
              </div>
              <div className="TermCondition-content-item-content">
                The terms "Swim Sync," "we," "us," and "our" refer to Advancer
                Global Digital Transformation, together with its employees,
                directors, affiliates, successors, and assigns.
              </div>
              <div className="TermCondition-content-item-content">
                The terms "you" and "your" refer to users of the Service,
                including account holders, administrators, instructors, parents,
                and students using Swim Sync for their respective purposes.
              </div>
              <div className="TermCondition-content-item-content">
                These T&C are effective upon your acceptance of the Terms or
                upon using any of the Services. These remain in effect until
                terminated by either party, though some provisions may survive
                termination as specified in these T&C or under applicable laws
                and regulations.
              </div>
            </div>
          </div>
          {/* 2. Definition of Terms */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              2. Definition of Terms
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                <strong>Account Holder</strong> – Refers to an individual or
                institution that registers on the Swim Sync platform to manage a
                swimming school, create classes, and track student progress.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Instructor</strong> – Refers to the swimming coach or
                teacher registered on Swim Sync to manage student lessons,
                schedules, and performance.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Parent/Guardian</strong> – Refers to the legal guardian
                of a student, responsible for managing their child's enrollment,
                class bookings, and payment for services on Swim Sync.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Student</strong> – Refers to the individual attending
                lessons or classes offered by a swimming school or instructor
                using Swim Sync.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>School/Institution</strong> – Refers to a swimming
                school or educational institution using Swim Sync to manage
                classes, staff, and operations.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Swim Sync Account</strong> – Refers to the user account
                created by schools, instructors, parents, or students, which
                allows them to access and use the Swim Sync platform.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Service</strong> – Refers to all the functions and tools
                provided through the Swim Sync platform, including class
                management, scheduling, progress tracking, payment processing,
                and communication features.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Classes</strong> – Refers to the structured swimming
                lessons offered by instructors through the Swim Sync platform,
                including details such as schedules, durations, and skill
                levels.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Assessment</strong> – Refers to the evaluation process
                conducted by instructors to gauge a student's progress, skill
                level, and readiness for advancement in swimming classes.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Staff</strong> – Refers to individuals employed or
                contracted by the swimming school or institution, including
                instructors, administrative personnel, and support staff using
                Swim Sync to facilitate operations.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Location</strong> – Refers to the physical facility or
                venue where swimming classes are conducted, managed by the
                school or institution using Swim Sync.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Payment Details</strong> – Refers to the information
                required from users, including credit card, bank account, or
                other payment methods, to process payments for classes or
                services through Swim Sync.
              </li>
              <li className="TermCondition-content-item-content li">
                <strong>Transaction</strong> – Refers to any action, whether
                financial or non-financial, performed on the Swim Sync platform,
                such as class booking, payments, account setup, or lesson
                scheduling.
              </li>
            </div>
          </div>
          {/* 3. Service Access and Usage */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              3. Service Access and Usage
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                To access and use Swim Sync, users must register an account and
                agree to provide accurate and up-to-date information. Swim Sync
                reserves the right to suspend or terminate accounts in case of
                inaccurate, incomplete, or false information.
              </li>
              <li className="TermCondition-content-item-content li">
                Users are responsible for maintaining the confidentiality of
                their login credentials, and Swim Sync is not liable for any
                unauthorized access resulting from a failure to safeguard
                account details.
              </li>
              <li className="TermCondition-content-item-content li">
                All transactions, including class bookings and payments, must be
                completed through the designated Swim Sync platform.
              </li>
            </div>
          </div>
          {/* 4. Payment and Fees */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              4. Payment and Fees
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                By using the payment services on Swim Sync, you agree to provide
                accurate and up-to-date payment details. Payments processed
                through Swim Sync will include applicable fees as communicated
                at the time of the transaction.
              </li>
              <li className="TermCondition-content-item-content li">
                Swim Sync uses third-party payment processors, and by providing
                payment information, you consent to your payment data being
                handled by these processors.
              </li>
              <li className="TermCondition-content-item-content li">
                Fees and rates for services provided by the platform are subject
                to change. Swim Sync will notify users of any updates to pricing
                before the changes take effect.
              </li>
            </div>
          </div>
          {/* 5. Data Privacy */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              5. Data Privacy
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                Swim Sync values the privacy and security of your personal data.
                The collection, use, and processing of personal data, including
                payment details, is governed by the Swim Sync Privacy Policy,
                which complies with the Thailand Personal Data Protection Act
                (PDPA), effective June 1, 2022, and other applicable laws of
                Thailand. Additionally, where applicable, we adhere to
                international standards such as the European Union’s General
                Data Protection Regulation (GDPR), enacted May 25, 2018.
              </li>
              <li className="TermCondition-content-item-content li">
                By using Swim Sync, you consent to the collection and processing
                of your personal information, including name, contact details,
                and payment information, as necessary for providing services on
                the platform.
              </li>
            </div>
          </div>
          {/* 6. Termination and Suspension */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              6. Termination and Suspension
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                Swim Sync reserves the right to suspend or terminate your
                account without prior notice for violations of these T&C or any
                unlawful activities.
              </li>
              <li className="TermCondition-content-item-content li">
                You may terminate your account at any time by contacting Swim
                Sync support. Upon termination, all pending transactions must be
                completed, and Swim Sync reserves the right to retain certain
                information as required by law.
              </li>
            </div>
          </div>
          {/* 7. Limitation of Liability */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              7. Limitation of Liability
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                Swim Sync will not be liable for any damages arising from your
                use of the Service, including but not limited to errors in
                scheduling, payment processing issues, or unauthorized access to
                your account.
              </li>
              <li className="TermCondition-content-item-content li">
                The Service is provided on an "as-is" basis, and Swim Sync makes
                no guarantees about the availability or functionality of the
                platform at all times.
              </li>
            </div>
          </div>
          {/* 8. Governing Law and Dispute Resolution */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              8. Governing Law and Dispute Resolution
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                These T&C are governed by the laws of Thailand. Any disputes
                arising from or in connection with these T&C shall be resolved
                through amicable negotiation. If negotiation fails, the dispute
                shall be referred to the competent courts of Thailand.
              </div>
            </div>
          </div>
          {/* 9. Amendments and Modifications */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              9. Amendments and Modifications
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                Swim Sync reserves the right to update or modify these T&C at
                any time. Users will be notified of significant changes through
                the platform, and continued use of the Service after such
                modifications constitutes acceptance of the updated Terms.
              </div>
            </div>
          </div>
          {/* 10. Intellectual Property Rights */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              10. Intellectual Property Rights
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content ">
                All intellectual property rights, including but not limited to
                copyrights, trademarks, patents, and trade secrets related to
                Swim Sync, its content, features, and functionality, are owned
                by Advancer Global Digital Transformation. Users are granted a
                limited, non-exclusive, non-transferable license to use Swim
                Sync solely for its intended purposes.
              </div>
            </div>
          </div>
          {/* 11. User Responsibilities and Prohibited Activities */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              11. User Responsibilities and Prohibited Activities
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content ">
                Users agree to use Swim Sync in compliance with all applicable
                laws and regulations.
              </div>
              <div className="TermCondition-content-item-content ">
                Prohibited activities include, but are not limited to:
              </div>
              <div className="TermCondition-content-item-content li">
                a) Unauthorized access or attempts to access other users'
                accounts
              </div>
              <div className="TermCondition-content-item-content li">
                b) Data scraping or using automated systems to extract data from
                Swim Sync
              </div>
              <div className="TermCondition-content-item-content li">
                c) Uploading or transmitting malicious code or viruses
              </div>
              <div className="TermCondition-content-item-content li">
                d) Using Swim Sync for any illegal or unauthorized purpose
              </div>
              <div className="TermCondition-content-item-content li">
                e) Interfering with or disrupting the integrity or performance
                of Swim Sync
              </div>
              <div className="TermCondition-content-item-content ">
                Violation of these terms may result in immediate termination of
                your account and potential legal action.
              </div>
            </div>
          </div>
          {/* 12. Content Ownership */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              12. Content Ownership
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                Users retain ownership of any content they create and upload to
                Swim Sync, including but not limited to lesson plans, student
                progress reports, and other user-generated content.
              </li>
              <li className="TermCondition-content-item-content li">
                By uploading content to Swim Sync, users grant Advancer a
                worldwide, non-exclusive, royalty-free license to use,
                reproduce, modify, and distribute such content solely for the
                purposes of operating and improving Swim Sync.
              </li>
            </div>
          </div>
          {/* 13. Indemnification */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              13. Indemnification
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content ">
                Users agree to indemnify, defend, and hold harmless Advancer,
                its officers, directors, employees, agents, and affiliates from
                and against any claims, liabilities, damages, losses, and
                expenses, including reasonable attorneys' fees, arising out of
                or in any way connected with your access to or use of Swim Sync,
                your violation of these Terms and Conditions, or your violation
                of any third-party right.
              </div>
            </div>
          </div>
          {/* 14. Disclaimer of Warranties */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              14. Disclaimer of Warranties
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                Swim Sync is provided on an "as-is" and "as available" basis,
                without any warranties of any kind, either express or implied.
              </li>
              <li className="TermCondition-content-item-content li">
                Advancer does not warrant that Swim Sync will be uninterrupted,
                error-free, or free from harmful components.
              </li>
              <li className="TermCondition-content-item-content li">
                Advancer disclaims all warranties, express or implied, including
                but not limited to implied warranties of merchantability,
                fitness for a particular purpose, and non-infringement.
              </li>
            </div>
          </div>
          {/* 15. Force Majeure */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              15. Force Majeure
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                Neither party shall be liable for any failure or delay in
                performance under these Terms and Conditions to the extent such
                failure or delay is caused by factors beyond that party's
                reasonable control, including but not limited to natural
                disasters, acts of government, civil unrest, or pandemic.
              </div>
            </div>
          </div>
          {/* 16. Age Restrictions */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              16. Age Restrictions
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                Swim Sync is intended for use by individuals who are at least 18
                years old. If you are under 18, you may use Swim Sync only with
                the involvement and consent of a parent or legal guardian.
              </li>
              <li className="TermCondition-content-item-content li">
                Student accounts may be created and managed by parents, legal
                guardians, or authorized school administrators, regardless of
                the student's age.
              </li>
            </div>
          </div>
          {/* 17. Data Backup */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              17. Data Backup
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                Users are solely responsible for maintaining their own backups
                of any data they consider important. Advancer does not guarantee
                the retention or recovery of any user data in the event of data
                loss or account termination.
              </div>
            </div>
          </div>
          {/* 18. Third-Party Services */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              18. Third-Party Services
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                Swim Sync may integrate with or provide links to third-party
                services. These services are subject to their own terms and
                conditions and privacy policies.
              </li>
              <li className="TermCondition-content-item-content li">
                Advancer is not responsible for any third-party services and
                disclaims all liability for any issues arising from their use.
              </li>
            </div>
          </div>
          {/* 19. Refund Policy */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              19. Refund Policy
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                All fees paid for Swim Sync services are non-refundable unless
                otherwise specified at the time of purchase or required by
                applicable law.
              </li>
              <li className="TermCondition-content-item-content li">
                In cases where a refund is warranted, it will be processed
                according to the payment method used for the original
                transaction.
              </li>
            </div>
          </div>
          {/* 20. Communication Preferences */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              20. Communication Preferences
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <li className="TermCondition-content-item-content li">
                By using Swim Sync, you consent to receive communications from
                us electronically, including emails, text messages, and in-app
                notifications.
              </li>
              <li className="TermCondition-content-item-content li">
                You can manage your communication preferences through your
                account settings or by contacting Swim Sync support.
              </li>
            </div>
          </div>
          {/* 21. Severability */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              21. Severability
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                If any provision of these Terms and Conditions is found to be
                unenforceable or invalid, that provision shall be limited or
                eliminated to the minimum extent necessary so that these Terms
                and Conditions shall otherwise remain in full force and effect
                and enforceable.
              </div>
            </div>
          </div>
          {/* 22. Entire Agreement */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              22. Entire Agreement
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                These Terms and Conditions constitute the entire agreement
                between you and Advancer regarding the use of Swim Sync,
                superseding any prior agreements between you and Advancer
                relating to your use of Swim Sync.
              </div>
            </div>
          </div>
          {/* 23. Language */}
          <div className="TermCondition-content-item">
            <div className="TermCondition-content-item--title">
              23. Language
            </div>
            <div className="TermCondition-content-item-content-wrapper">
              <div className="TermCondition-content-item-content">
                These Terms and Conditions are written in English. If we provide
                a translation into other languages, the English version shall
                prevail in case of any discrepancies.
              </div>
              <div className="TermCondition-content-item-content">
                By using Swim Sync, you acknowledge that you have read,
                understood, and agree to be bound by these Terms and Conditions.
              </div>
            </div>
          </div>
          <></>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default TermCondition;
