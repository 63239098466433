import { useNavigate } from "react-router-dom";
import "./desktop.scss";

const BookDemo = () => {
  const navigate = useNavigate();
  return (
    <div className="AdministrativeTools-book-demo">
      <div className="AdministrativeTools-book-demo--wrapper">
        <div className="AdministrativeTools-book-demo--title">
          Ready to take your swim school management to the next level?
          <br />
          Discover the power of Swim Sync today!
        </div>
        <div className="AdministrativeTools-book-demo--buttonWrapper">
          <button
            className="AdministrativeTools-book-demo--button"
            onClick={() => {
              // Mở widget khi nhấn nút
              window.Calendly.showPopupWidget(
                "https://calendly.com/swimsync-support/swim-sync-demo-booking"
              );
            }}
          >
            Request Demo
          </button>
          <button
            className="AdministrativeTools-book-demo--button"
            onClick={() => navigate("/resources")}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookDemo;
