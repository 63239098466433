import "./desktop.scss";

import { HiPlus } from "react-icons/hi";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import { Fragment, memo } from "react";
import { HiMinus } from "react-icons/hi2";

const listFAQs = [
  {
    question: "What does the setup fee cover?",
    answer:
      "The setup fee covers the initial configuration of your account, custom branding, integrations, staff training, and personalised onboarding support. It's designed to ensure a smooth transition and maximise the value you get from Swim Sync from the start.",
  },
  {
    question: "Is the setup fee refundable?",
    answer:
      "The setup fee is non-refundable as it covers the costs of services provided during the onboarding process.",
  },
  {
    question: "Are there any other one-time fees?",
    answer:
      "The $899 per location setup fee is the only one-time fee. All other costs are part of your chosen subscription plan.",
  },
  {
    question: "Can the setup fee be spread out over multiple payments?",
    answer:
      "For multi-location swim schools, we offer the option to spread the setup fee over the first three months of your subscription. Please contact our sales team for more details on this option.",
  },
];

const FAQs = () => {
  return (
    <div className="FAQs">
      <SlideUpOnScroll>
        <div className="FAQs__wrapper">
          <div className="FAQs__header">
            <h2 className="FAQs__header__title">FAQs</h2>
          </div>
          <div className="FAQs__list">
            {listFAQs?.map((faq, key) => (
              <Fragment key={key}>
                <input id={`${key}`} type="checkbox" />
                <label htmlFor={`${key}`}>
                  <div className="FAQs__list__item">
                    <h3 className="FAQs__list__item-title cursor">
                      {faq?.question}
                    </h3>
                    <HiPlus className="cursor plus" size={20} />
                    <HiMinus className="cursor minus" size={20} />
                  </div>
                  <p className="FAQs__answer">{faq?.answer}</p>
                </label>
              </Fragment>
            ))}
          </div>
        </div>
      </SlideUpOnScroll>
    </div>
  );
};
export default memo(FAQs);
