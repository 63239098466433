import "./desktop.scss";

const BookDemo = () => {
  return (
    <div className="FeatureMemberPortal-book-demo">
      <div className="FeatureMemberPortal-book-demo--wrapper">
        <div className="FeatureMemberPortal-book-demo--title">
          Ready to dive into a better swim school experience?
          <br />
          Join the Swim Sync family today!
        </div>
        <a
          href="https://uat.swimsync.app/register"
          className="FeatureMemberPortal-book-demo--button"
        >
          Sign Up Now
        </a>
      </div>
    </div>
  );
};

export default BookDemo;
