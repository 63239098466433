import React from "react";
import "./desktop.scss";
import SlideUpOnScroll from "components/SlideUpOnScroll";

const HowItWorks = () => {
  const data1 = [
    {
      id: 1,
      image: "/images/howItWorks/image1.png",
      title: "Test the Waters",
      description:
        "Dive into a personalized demo and see Swim Sync in action! If we make a splash and tick all your boxes, we'll have you riding the wave of exceptional swim school management faster than an Olympic freestyle sprint. Ready to dip your toes in?",
    },
    {
      id: 2,
      image: "/images/howItWorks/image2.png",
      title: "Set Your Course",
      description:
        "Customize your digital swim school. Input your locations, programs, and staff details. Watch as Swim Sync transforms into your personalized aquatic command centre.",
    },
  ];
  const data2 = [
    {
      id: 3,
      image: "/images/howItWorks/image3.png",
      title: "Assemble Your Dream Team",
      description:
        "Invite your staff and instructors to join. Assign roles, set permissions, and watch as your team embraces the new streamlined workflow.",
    },
    {
      id: 4,
      image: "/images/howItWorks/image4.png",
      title: "Welcome Your Swimmers",
      description:
        "Introduce parents and students to their new Member Portal. They'll love the ease of enrollment, progress tracking, and communication features.",
    },
  ];
  return (
    <div className="howItWorks">
      <div className="howItWorks__wrapper">
        <div className="howItWorks__header">
          <div className="howItWorks__header__title">How it works</div>
          <div className="howItWorks__header__description">
            Dive In with Swim Sync in 4 Easy Strokes
          </div>
        </div>
        <div className="howItWorks__content">
          {data1.map((item, key) => (
            <SlideUpOnScroll key={key}>
              <div className="howItWorks__content__item" key={key}>
                <div className="item__image">
                  <img src={item?.image} alt={item?.title} />
                </div>
                <div className="item__info">
                  <div className="item__info__title">{item?.title}</div>
                  <div className="item__info__description">
                    {item?.description}
                  </div>
                </div>
              </div>
            </SlideUpOnScroll>
          ))}
          {data2.map((item, key) => (
            <SlideUpOnScroll threshold={0.4} key={key}>
              <div className="howItWorks__content__item" key={key}>
                <div className="item__image">
                  <img src={item?.image} alt={item?.title} />
                </div>
                <div className="item__info">
                  <div className="item__info__title">{item?.title}</div>
                  <div className="item__info__description">
                    {item?.description}
                  </div>
                </div>
              </div>
            </SlideUpOnScroll>
          ))}
        </div>
      </div>
      <SlideUpOnScroll threshold={0.2}>
        <div className="howItWorks__footer">
          <div className="howItWorks__footer__item">
            <img src="/images/howItWorks/trophy.png" alt="trophy" />
            <div className="title">Ready, Set, Swim!</div>
            <div className="description">
              You’re all set to transform your swim school management with Swim
              Sync. Experience smoother operations, happier parents, and more
              time dedicated to what you do best-creating confident swimmers!
              Dive into a world where efficiency meets excellence, and watch
              your swim school flourish like never before!
            </div>
          </div>
        </div>
      </SlideUpOnScroll>
    </div>
  );
};

export default HowItWorks;
