import Slider from "react-slick";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import "./desktop.scss";

const Introduction = () => {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 2.65,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000
  };
  const imageData: string[] = [
    "/images/introductionMP/image5.png",
    "/images/introductionMP/image6.png",
    "/images/introductionMP/image7.png",
    "/images/introductionMP/image8.png"
  ];

  const listFeatures = [
    {
      id: 1,
      title: "Easy Enrollment",
      description:
        "Register for classes and manage your swim programs effortlessly.",
      icon: "/svgs/introduction/clipboard.svg",
      subTitle: "Streamlined Enrollment Process",
      subDescription: "Take the hassle out of class registration:",
      subList: [
        "Browse available classes and programs suited to your child's level",
        "Easily enroll in multiple classes or for multiple children",
        "Manage waitlist status and receive notifications when spots open up",
        "View class details, including instructor bios and pool information",
        "Seamlessly renew or change enrollments as your child progresses"
      ]
    },
    {
      id: 2,
      title: "Schedule Management",
      description: "View and manage your class schedules with ease.",
      icon: "/svgs/introduction/calendar-swim.svg",
      subTitle: "Flexible Schedule Management",
      subDescription: "Stay on top of your swim school commitments:",
      subList: [
        "Access a personalized calendar showing all enrolled classes and events",
        "Receive reminders for upcoming classes and special events",
        "Easily request make-up classes or schedule changes",
        "View pool schedules and availability for open swim sessions",
        "Sync your swim schedule with your personal digital calendar"
      ]
    },
    {
      id: 3,
      title: "Progress Tracking",
      description: "Monitor your child's skill development and achievements.",
      icon: "/svgs/introduction/chart-swim.svg",
      subTitle: "Comprehensive Progress Tracking",
      subDescription: "Watch your child's swimming skills flourish:",
      subList: [
        "View detailed progress reports and skill assessments",
        "Track achievements and milestones with a visual progress chart",
        "Access instructor feedback and recommendations",
        "Set and monitor personal goals for your child's swim journey",
        "Celebrate successes with shareable digital badges and certificates"
      ]
    },
    {
      id: 4,
      title: "Seamless Communication",
      description:
        "Stay connected with instructors and receive important updates.",
      icon: "/svgs/introduction/messages.svg",
      subTitle: "Seamless Communication Hub",
      subDescription: "Stay informed and connected:",
      subList: [
        "Receive important notifications via SMS, email, or in-app alerts",
        "Communicate directly with instructors through secure messaging",
        "Access a centralized news feed for school-wide announcements",
        "Opt-in to emergency alerts for weather-related closures or changes",
        "Provide feedback and participate in surveys to help improve the swim school experience"
      ]
    },
    {
      id: 5,
      title: "Secure Payments",
      description: "Handle all financial transactions safely and conveniently.",
      icon: "/svgs/introduction/shield-dollar.svg",
      subTitle: "Secure and Convenient Payments",
      subDescription: "Manage your swim school finances with peace of mind:",
      subList: [
        "View and pay invoices securely within the portal",
        "Set up automatic payments for recurring fees",
        "Access detailed transaction history and receipts",
        "Manage multiple payment methods for flexibility",
        "Receive notifications for upcoming payments or overdue balances"
      ]
    },
    {
      id: 6,
      title: "Resource Access",
      description: "Access valuable swimming resources and learning materials.",
      icon: "/svgs/introduction/block-waves.svg",
      subTitle: "Comprehensive Resource Library",
      subDescription: "Enhance your child's learning beyond the pool:",
      subList: [
        "Access a variety of educational materials, including videos and articles",
        "Download swim level guides and skill checklists",
        "View safety tips and best practices for swimmers and parents",
        "Access FAQ sections for common queries",
        "Explore recommended swimming gear and equipment"
      ]
    }
  ];

  return (
    <>
      <div className="FeatureMemberPortal-introduction">
        <SlideUpOnScroll>
          <div className="FeatureMemberPortal-introduction--header">
            <div className="FeatureMemberPortal-introduction--header--description">
              Empower your swim school management with Swim Sync's comprehensive
              Administrative Tools. Designed to streamline operations, enhance
              efficiency, and provide valuable insights, our admin suite gives
              you complete control over your swim school's day-to-day operations
              and long-term strategy.
            </div>
          </div>
        </SlideUpOnScroll>
        <SlideUpOnScroll>
          <div className="FeatureMemberPortal-introduction--slider-wrapper">
            <Slider {...settings}>
              {imageData.map((item, key) => (
                <div
                  key={key}
                  className="FeatureMemberPortal-introduction--slider-wrapper--image"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </SlideUpOnScroll>
      </div>
      <div className="FeatureMemberPortal-introduction--featuresContainer">
        <div className="FeatureMemberPortal-introduction--featuresWrapper">
          {listFeatures.map((item, key) => (
            <div
              key={key}
              className="FeatureMemberPortal-introduction--featuresItem"
            >
              <div className="FeatureMemberPortal-introduction--featuresItemMain">
                <div className="FeatureMemberPortal-introduction--featuresItemLeft">
                  <img
                    src={item.icon}
                    alt=""
                    className="FeatureMemberPortal-introduction--featuresItemLeft--icon"
                  />
                  <div className="FeatureMemberPortal-introduction--featuresItemLeft--title">
                    {item.title}
                  </div>
                  <div className="FeatureMemberPortal-introduction--featuresItemLeft--description">
                    {item.description}
                  </div>
                </div>
                <div className="FeatureMemberPortal-introduction--featuresItemRight">
                  <p className="FeatureMemberPortal-introduction--featuresItemRight--subTitle">
                    {item.subTitle}
                  </p>
                  <p className="FeatureMemberPortal-introduction--featuresItemRight--subDescription">
                    {item.subDescription}
                  </p>
                  <ul>
                    {item.subList.map((li, liIndex) => {
                      return <li key={liIndex}>{li}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Introduction;
