import React from "react";

import "./desktop.scss";
import SlideUpOnScroll from "components/SlideUpOnScroll";

const SocialProof = () => {
  const socialProof = [
    {
      id: 1,
      image: "/svgs/safety-centric.svg",
      title: "Trusted by 100+ swim schools worldwide",
      description: "We believe that stronger swimmers are safer swimmers.",
    },
    {
      id: 2,
      image: "/svgs/customized-learning.svg",
      title: "Managing the Progress of Over 10,000 Swimmers",
      description:
        "We provide personalized learning plans tailored to each child's skill level and learning pace.",
    },
  ];

  return (
    <div className="social-proof">
      <div className="social-proof__title">Social Proof</div>

      <SlideUpOnScroll>
        <div className="social-proof__content">
          {socialProof.map((item, key) => (
            <div className="social-proof__content-item" key={key}>
              <div className="item__image">
                <img src={item?.image} alt={item?.title} />
              </div>
              <div className="item__info">
                <div className="item__info__title">{item?.title}</div>
                <div className="item__info__description">
                  {item?.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </SlideUpOnScroll>
    </div>
  );
};

export default SocialProof;
