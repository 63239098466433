const UpdateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none">
      <g clipPath="url(#clip0_566_2615)">
        <path
          d="M20.668 14.6667H12.0013C10.7146 14.6667 9.66797 13.62 9.66797 12.3334V10.3334C9.66797 9.78137 10.116 9.33337 10.668 9.33337C11.22 9.33337 11.668 9.78137 11.668 10.3334V12.3334C11.668 12.5174 11.8173 12.6667 12.0013 12.6667H20.668C20.852 12.6667 21.0013 12.5174 21.0013 12.3334V10.3334C21.0013 9.78137 21.4493 9.33337 22.0013 9.33337C22.5533 9.33337 23.0013 9.78137 23.0013 10.3334V12.3334C23.0013 13.62 21.9546 14.6667 20.668 14.6667Z"
          fill="#2EB67D"
        />
        <path
          d="M16.334 10.6667C15.782 10.6667 15.334 10.2187 15.334 9.66671V1.33337C15.334 0.781374 15.782 0.333374 16.334 0.333374C16.886 0.333374 17.334 0.781374 17.334 1.33337V9.66671C17.334 10.2187 16.886 10.6667 16.334 10.6667Z"
          fill="#2EB67D"
        />
        <path
          d="M19.333 5.00003C19.077 5.00003 18.821 4.9027 18.6264 4.7067L16.333 2.4147L14.0397 4.70803C13.649 5.0987 13.0157 5.0987 12.625 4.70803C12.2344 4.31737 12.2344 3.68403 12.625 3.29337L15.625 0.293366C16.0157 -0.0973005 16.649 -0.0973005 17.0397 0.293366L20.0397 3.29337C20.4304 3.68403 20.4304 4.31737 20.0397 4.70803C19.845 4.9027 19.589 5.00003 19.333 5.00003Z"
          fill="#2EB67D"
        />
        <path
          d="M30.0007 28H2.66732C1.38065 28 0.333984 26.9534 0.333984 25.6667V7.66671C0.333984 6.38004 1.38065 5.33337 2.66732 5.33337H7.33398C7.88732 5.33337 8.33398 5.78137 8.33398 6.33337C8.33398 6.88537 7.88732 7.33337 7.33398 7.33337H2.66732C2.48332 7.33337 2.33398 7.48271 2.33398 7.66671V25.6667C2.33398 25.8507 2.48332 26 2.66732 26H30.0007C30.1847 26 30.334 25.8507 30.334 25.6667V7.66671C30.334 7.48271 30.1847 7.33337 30.0007 7.33337H25.334C24.782 7.33337 24.334 6.88537 24.334 6.33337C24.334 5.78137 24.782 5.33337 25.334 5.33337H30.0007C31.2873 5.33337 32.334 6.38004 32.334 7.66671V25.6667C32.334 26.9534 31.2873 28 30.0007 28Z"
          fill="#2EB67D"
        />
        <path
          d="M31.334 23.3334H1.33398C0.781984 23.3334 0.333984 22.8854 0.333984 22.3334C0.333984 21.7814 0.781984 21.3334 1.33398 21.3334H31.334C31.886 21.3334 32.334 21.7814 32.334 22.3334C32.334 22.8854 31.886 23.3334 31.334 23.3334Z"
          fill="#2EB67D"
        />
        <path
          d="M20.6668 32H12.0001C11.5961 32 11.2308 31.756 11.0761 31.3827C10.9214 31.0093 11.0068 30.58 11.2934 30.2933C12.6814 28.9053 12.9801 26.8893 12.9828 26.8707C13.0548 26.3227 13.5588 25.932 14.1041 26.0093C14.6521 26.0813 15.0374 26.584 14.9654 27.1307C14.9548 27.2093 14.7694 28.552 13.9734 30H18.6708C17.8828 28.56 17.6868 27.216 17.6761 27.1373C17.6001 26.5907 17.9814 26.0867 18.5294 26.0093C19.0814 25.932 19.5801 26.3147 19.6574 26.8613C19.6668 26.9213 19.9668 28.82 21.2361 30.1773C21.4961 30.3587 21.6668 30.6587 21.6668 31C21.6668 31.552 21.2188 32 20.6668 32Z"
          fill="#2EB67D"
        />
      </g>
      <defs>
        <clipPath id="clip0_566_2615">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UpdateIcon;
