import "./desktop.scss";
import "./mobile.scss";

import { memo, useEffect, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import SideBarItem from "./SideBarItem";
import { HiMenu } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import LoginModal from "components/LoginModal";

interface Props {
  autoScrolled?: boolean;
}
const Header: React.FC<Props> = ({ autoScrolled = false }) => {
  const navigate = useNavigate();
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 76) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleCollapse = (): void => {
    setIsShowSidebar((pre) => !pre);
  };

  const menuData = [
    {
      title: "Platform",
      link: "",
      children: [
        {
          title: "Command Center",
          subTitle: "Streamline Operations",
          path: "/feature/staff",
          icon: "/svgs/header/staff.svg",
        },
        {
          title: "Swim Portal",
          subTitle: "Engage Parents",
          path: "/feature/member",
          icon: "/svgs/header/member.svg",
        },
        {
          title: "Administrative Tools",
          subTitle: "Optimise Management",
          path: "/feature/administrative-tools",
          icon: "/svgs/header/administrative-tools.svg",
        },
        {
          title: "Reporting & Analytics",
          subTitle: "Data-Driven Insights",
          path: "/feature/reporting-and-analytics",
          icon: "/svgs/header/report.svg",
        },
        {
          title: "Integration & Security",
          subTitle: "Seamless & Safe",
          path: "/feature/integration-and-security",
          icon: "/svgs/header/integration.svg",
        },
      ],
    },
    {
      title: "Pricing",
      link: "/pricing",
    },
    {
      title: "Resources",
      link: "/resources",
    },
    {
      title: "About",
      link: "/about",
    },
    {
      title: "Contact",
      link: "/contact",
    },
  ];

  return (
    <>
      <LoginModal
        open={isShowLoginModal}
        onClose={() => setIsShowLoginModal(false)}
      />
      <div
        className={`app-header ${isScrolled ? "scrolled" : ""} ${
          autoScrolled ? "autoScrolled" : ""
        }`}
      >
        <div className="app-header-wrapper">
          <HiMenu size={24} onClick={() => toggleCollapse()} />

          <img
            src={"/images/logo.png"}
            className="app-logo"
            style={{ cursor: "pointer" }}
            alt="logo"
            onClick={() => navigate("/")}
          />
          <div className="menu-wrapper">
            {menuData.map((item, index) => (
              <div
                className="menu-item"
                key={index}
                onClick={() => (item.link ? navigate(item.link) : {})}
              >
                {item.title}
                {item.children && (
                  <div className="menu-item__sub">
                    {item.children.map((child, index) => (
                      <div
                        key={index}
                        className="menu-item__sub--item"
                        onClick={() => navigate(child.path)}
                      >
                        <img src={child.icon} alt="icon" />

                        <div className="info">
                          <div className="title">{child.title}</div>
                          <div className="subTitle">{child.subTitle}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="app-header-right">
            <div
              className="sign-up-wrapper"
              onClick={() => navigate("/interested")}
            >
              <div className="text">I’m Interested</div>
            </div>
            <div
              className="login-wrapper"
              onClick={() => setIsShowLoginModal(true)}
            >
              <div className="text">Log in</div>
            </div>
            <div className="book-demo-wrapper">
              <div
                className="text"
                onClick={() => {
                  // Mở widget khi nhấn nút
                  window.Calendly.showPopupWidget(
                    "https://calendly.com/swimsync-support/swim-sync-demo-booking"
                  );
                }}
              >
                Book a Demo
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowSidebar && (
        <div className="sidebar sidebar--open">
          <div className="sidebar__menu__list">
            <div className="sidebar__menu__list__icon sidebar__menu__list__icon--header">
              <img src="/svgs/logo-sign.svg" alt="logo" />
              <HiXMark
                size={22}
                style={{ cursor: "pointer" }}
                onClick={() => toggleCollapse()}
              />
            </div>
            <div className={`sidebar__menu__list__item`}>
              {menuData.map((item, index: number) => (
                <SideBarItem key={index} item={item} isActive={false} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Header);
