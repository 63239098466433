import Header from "components/header/Header";
import "./desktop.scss";
import Footer from "components/footer";

const DataPrivacy = () => {
  return (
    <div className="DataPrivacy">
      <Header autoScrolled />
      <div className="DataPrivacy-wrapper">
        <div className="DataPrivacy-header-title">
          <img src="/images/lock.png" alt="" />
          Data Privacy
        </div>
        <div className="DataPrivacy-content">
          {/* 1. Our Approach to Privacy */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              1. Our Approach to Privacy
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                Advancer Global Digital Transformation ("Advancer," "we," "our,"
                or "us") is committed to protecting your privacy and maintaining
                transparency in how we handle your personal information. This
                privacy policy is designed to help you understand how we
                collect, use, store, share, and protect the personal information
                you entrust to us through our Swim Sync platform.
              </div>
              <div className="DataPrivacy-content-item-content">
                Swim Sync is a platform designed to serve swimming schools and
                institutions, helping them manage students, track performance,
                and facilitate swimming-related programs. This privacy policy
                applies to both institutions and users of Swim Sync.
              </div>
            </div>
          </div>
          {/* 2. Legal Compliance */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              2. Legal Compliance
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                Our privacy policy values and protects your personal information
                in accordance with the Thailand Personal Data Protection Act
                (PDPA), effective June 1, 2022, and other applicable laws of
                Thailand. Additionally, where applicable, we adhere to
                international standards such as the European Union’s General
                Data Protection Regulation (GDPR), enacted May 25, 2018.
              </div>
            </div>
          </div>
          {/* 3. Consent */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">3. Consent</div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                By using Swim Sync, you consent to the Privacy Policy of
                Advancer Global Digital Transformation, its subsidiaries, and
                affiliates, as well as the collection, use, access, transfer,
                storage, and processing of your personal information as
                described in this Privacy Policy.
              </div>
            </div>
          </div>
          {/* 4. Why We Collect Personal Information */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              4. Why We Collect Personal Information
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We collect personal information to improve our platform and the
                services we provide to institutions and their users.
                Specifically, we use your information to:
              </div>
              <div className="DataPrivacy-content-item-content">
                a) Manage and maintain your Swim Sync account.
              </div>
              <div className="DataPrivacy-content-item-content">
                b) Provide customer support and ensure timely communication
                between schools and their students or parents.
              </div>
              <div className="DataPrivacy-content-item-content">
                c) Monitor the quality and security of the Swim Sync platform
                and improve user experience.
              </div>
              <div className="DataPrivacy-content-item-content">
                d) Facilitate payment transactions and store payment history
                securely.
              </div>
              <div className="DataPrivacy-content-item-content">
                e) Analyze data for research and marketing initiatives.
              </div>
              <div className="DataPrivacy-content-item-content">
                f) Prevent fraud, unauthorized access, or misuse of the
                platform.
              </div>
              <div className="DataPrivacy-content-item-content">
                g) Perform other processing or disclosures as required under law
                or regulations.
              </div>
              <div className="DataPrivacy-content-item-content">
                With your consent, we may also:
              </div>
              <div className="DataPrivacy-content-item-content">
                h) Customize your Swim Sync experience based on your preferences
                and activity.
              </div>
              <div className="DataPrivacy-content-item-content">
                i) Share anonymized data with business partners for research and
                marketing purposes.
              </div>
            </div>
          </div>
          {/* 5. Types of Information We Collect */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              5. Types of Information We Collect
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We collect the following types of information:
              </div>
              <div className="DataPrivacy-content-item-content title">
                a) Personal Information:
              </div>
              <li className="DataPrivacy-content-item-content li">
                Names, contact details (address, phone number, email), and
                personal documents (e.g., identification numbers) for
                administrators, instructors, students, or parents/guardians.
              </li>
              <li className="DataPrivacy-content-item-content li">
                Details of any health conditions, allergies, or medical concerns
                provided for the purpose of ensuring safe participation in
                swimming lessons
              </li>
              <li className="DataPrivacy-content-item-content li">
                Information necessary for user management and billing, including
                payment details such as credit card or bank information.
              </li>

              <div className="DataPrivacy-content-item-content title">
                b) Usage Data:
              </div>
              <li className="DataPrivacy-content-item-content li">
                Records of student attendance, performance, and progress within
                the swimming programs managed via Swim Sync.
              </li>
              <li className="DataPrivacy-content-item-content li">
                Information on the services accessed, such as lessons booked,
                schedules, and feedback on performance.
              </li>
              <li className="DataPrivacy-content-item-content li">
                To ensure that the student’s health needs are met during lessons
              </li>
              <div className="DataPrivacy-content-item-content title">
                c) Payment Information:
              </div>
              <li className="DataPrivacy-content-item-content li">
                Details required to process payments for services or
                subscription fees, including credit card numbers and billing
                addresses. Payment information is encrypted and processed
                through secure third-party payment gateways.
              </li>
              <div className="DataPrivacy-content-item-content title">
                d) Device Information:
              </div>
              <li className="DataPrivacy-content-item-content li">
                Device details used to access Swim Sync, including IP address,
                operating system, browser type, and device identifiers to
                safeguard user accounts and optimize service delivery.
              </li>
            </div>
          </div>
          {/* 6. Sources of Information */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              6. Sources of Information
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We collect information from:
              </div>
              <div className="DataPrivacy-content-item-content">
                a) Direct input from school administrators, staff, students, or
                parents when registering and using Swim Sync.
              </div>
              <div className="DataPrivacy-content-item-content">
                b) Automated systems that capture usage data when you interact
                with the Swim Sync platform.
              </div>
              <div className="DataPrivacy-content-item-content">
                c) Payment processors for handling billing details and
                transaction histories.
              </div>
            </div>
          </div>
          {/* 7. Information Sharing */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              7. Information Sharing
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We share your personal information only when necessary and in a
                secure manner:
              </div>
              <div className="DataPrivacy-content-item-content">
                <strong>a) Service Providers:</strong> We may share personal
                data with trusted third-party providers who help facilitate
                payment processing, data hosting, or customer support.
              </div>
              <div className="DataPrivacy-content-item-content">
                <strong>b) Legal Obligations:</strong> We may disclose
                information if required to comply with legal obligations or
                respond to lawful government requests.
              </div>
              <div className="DataPrivacy-content-item-content">
                <strong>c) With Your Consent:</strong> If you give consent, we
                may share anonymized data for marketing research or analysis
                with business partners.
              </div>
            </div>
          </div>
          {/* 8. Data Protection Measures */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              8. Data Protection Measures
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We take appropriate technical and organizational measures to
                ensure the security of your personal information. This includes
                encryption of sensitive payment data, restricted access to
                personal data, and secure storage on servers compliant with
                industry standards.
              </div>
            </div>
          </div>
          {/* 9. Your Rights */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              9. Your Rights
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                As a user of Swim Sync, you have the right to:
              </div>
              <div className="DataPrivacy-content-item-content">
                a) Access, update, or request the deletion of your personal
                information.
              </div>
              <div className="DataPrivacy-content-item-content">
                b) Withdraw your consent to the collection and use of your data
                at any time, subject to legal or contractual restrictions.
              </div>
              <div className="DataPrivacy-content-item-content">
                c) Lodge complaints or inquiries regarding our handling of your
                data.
              </div>
              <div className="DataPrivacy-content-item-content">
                d) Restrict processing of your personal data under certain
                circumstances.
              </div>
              <div className="DataPrivacy-content-item-content">
                e) Object to processing of your personal data on grounds
                relating to your particular situation.
              </div>
              <div className="DataPrivacy-content-item-content">
                f) Request erasure of your personal data under certain
                circumstances.
              </div>
              <div className="DataPrivacy-content-item-content">
                g) Have inaccurate personal data rectified, or completed if it
                is incomplete.
              </div>
              <div className="DataPrivacy-content-item-content">
                h) Receive your personal data in a structured, commonly used,
                and machine-readable format (data portability).
              </div>
              <div className="DataPrivacy-content-item-content">
                i) Not be subject to automated decision-making, including
                profiling, which produces legal effects concerning you or
                similarly significantly affects you, except where permitted by
                applicable law.
              </div>
              <div className="DataPrivacy-content-item-content">
                To exercise any of these rights, please contact us using the
                information provided at the end of this policy.
              </div>
            </div>
          </div>
          {/* 10. International Data Transfers */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              10. International Data Transfers
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We may transfer, process, and store personal data we collect
                through Swim Sync outside of Thailand. We ensure that any
                international transfer of personal data complies with applicable
                laws and regulations. We implement appropriate safeguards, such
                as standard contractual clauses, to protect your personal data
                during international transfers.
              </div>
            </div>
          </div>
          {/* 11. Data Retention */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              11. Data Retention
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We retain personal data only for as long as necessary to fulfill
                the purposes for which it was collected, including for legal,
                accounting, or reporting requirements. Our retention periods are
                determined based on:
              </div>
              <div className="DataPrivacy-content-item-content">
                a) The length of our ongoing relationship with you and provision
                of services.
              </div>
              <div className="DataPrivacy-content-item-content">
                b) Legal obligations.
              </div>
              <div className="DataPrivacy-content-item-content">
                c) Advisability in light of our legal position (e.g., statutes
                of limitations, litigation, regulatory investigations).
              </div>
            </div>
          </div>
          {/* 12. Cookie Policy */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              12. Cookie Policy
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                Swim Sync uses cookies and similar technologies to enhance user
                experience, analyze usage patterns, and deliver personalized
                content. By using our platform, you consent to our use of
                cookies in accordance with this privacy policy. You can manage
                your cookie preferences through your browser settings. For more
                detailed information, please refer to our separate Cookie Policy
                available on our website.
              </div>
            </div>
          </div>
          {/* 13. Children's Privacy */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              13. Children's Privacy
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We comply with all applicable laws regarding the protection of
                children's personal data. Where required by law, we will seek
                parental consent before collecting personal information from
                children. Parents or guardians have the right to review, modify,
                or request deletion of their child's personal data.
              </div>
            </div>
          </div>
          {/* 14. Data Breach Notification */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              14. Data Breach Notification
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                In the event of a data breach, we will notify affected users
                without undue delay, in accordance with applicable laws and
                regulations. This notification will include information about
                the nature of the breach, our response measures, and
                recommendations for protecting yourself.
              </div>
            </div>
          </div>
          {/* 15. Marketing Communications */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              15. Marketing Communications
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We will obtain your specific consent before sending marketing
                communications about Swim Sync products, services, and events.
                You have the right to withdraw your consent at any time.
              </div>
            </div>
          </div>
          {/* 16. Automated Decision Making */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              16. Automated Decision Making
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                Where we use automated decision-making, we will provide you with
                information about the logic involved, as well as the
                significance and the envisaged consequences of such processing
                for you.
              </div>
            </div>
          </div>
          {/* 17. Third-Party Links */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              17. Third-Party Links
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                The Swim Sync platform may contain links to third-party websites
                or services. This privacy policy applies only to our platform.
                We encourage you to read the privacy policies of any third-party
                sites before providing personal information.
              </div>
            </div>
          </div>
          {/* 18. Regular Privacy Audits */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              18. Regular Privacy Audits
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We conduct regular privacy audits to ensure ongoing compliance
                with this privacy policy, applicable laws, and industry best
                practices.
              </div>
            </div>
          </div>
          {/* 19. Localisation */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              19. Localisation
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                This privacy policy is designed to comply with Thai law and,
                where applicable, other international data protection standards.
                In the event of any conflict between this policy and applicable
                local laws, the local laws will prevail.
              </div>
            </div>
          </div>
          {/* 20. Changes to This Policy */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              20. Changes to This Policy
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                We may update this privacy policy from time to time. We will
                notify you of any material changes by posting the updated policy
                on our website and, where required by law, seek your consent.
              </div>
            </div>
          </div>
          {/* 21. Contact Us */}
          <div className="DataPrivacy-content-item">
            <div className="DataPrivacy-content-item--title">
              21. Contact Us
            </div>
            <div className="DataPrivacy-content-item-content-wrapper">
              <div className="DataPrivacy-content-item-content">
                If you have any questions, concerns, or requests regarding this
                privacy policy or our data processing practices, please contact
                us at:
              </div>
              <div className="DataPrivacy-content-item-content">
                Global Advancer Co., Ltd
              </div>
              <div className="DataPrivacy-content-item-content">
                Level 18, Park Ventures Ecoplex, 57 Wireless Road, Lumpini,
                Pathumwan, Bangkok, Thailand
              </div>
              <div className="DataPrivacy-content-item-content">
                Email: hello@swimsync.app
              </div>
              <div className="DataPrivacy-content-item-content">
                Phone: +1 864 528 3434
              </div>
              <div className="DataPrivacy-content-item-content">
                By using Swim Sync, you acknowledge that you have read and
                understood this privacy policy and agree to its terms.
              </div>
            </div>
          </div>
          <></>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default DataPrivacy;
