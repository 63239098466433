import Slider from "react-slick";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import "./desktop.scss";

const Introduction = () => {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 2.65,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const imageData: string[] = [
    "/images/introductionMP/image5.png",
    "/images/introductionMP/image6.png",
    "/images/introductionMP/image7.png",
    "/images/introductionMP/image8.png",
  ];

  const listFeatures = [
    {
      id: 1,
      title: "Data Protection",
      description: "Keep Your Data Secure and Confidential",
      icon: "/svgs/introduction/student.svg",
      subTitle: "Data Protection Features Include:",
      subDescription:
        "Your swim school data deserves top-notch protection. Our system employs rigorous data encryption methods to safeguard information both at rest and in transit",
      subList: [
        "Server-Side Encryption (SSE): Ensures that data is encrypted while stored, protecting it from unauthorized access",
        "HTTPS: Secures data in transit, blocking eavesdropping and guaranteeing safe communication between users and servers.",
      ],
    },
    {
      id: 2,
      title: "User Authentication",
      description: "Streamlined, Passwordless Login for Easy Access",
      icon: "/svgs/introduction/class.svg",
      subTitle: "User Authentication Features Include",
      subDescription:
        "Ensure only authorized users can access your system with a seamless and secure login process that eliminates password hassle and reduces security risks",
      subList: [
        "Passwordless OTP via Email: Simplifies login by sending a one-time password directly to the user’s email, removing the need for traditional passwords",
        "JWT (JSON Web Token): Provides secure, session-based access so users can navigate protected areas without needing to log in repeatedly",
        "Customizable login flows and branding options",
        "Handle make-up classes and reschedules effortlessly",
      ],
    },
    {
      id: 3,
      title: "System Integrity",
      description: "Control Access and Maintain System Integrity",
      icon: "/svgs/introduction/instructor.svg",
      subTitle: "System Integrity Feature Includes",
      subDescription:
        "Protect sensitive information by controlling user access based on roles, so that only authorized personnel can access specific resources within the system",
      subList: [
        "Role-Based Access Control (RBAC): Limits access to essential resources based on user roles, ensuring each user has the appropriate permissions for their responsibilities",
      ],
    },
    {
      id: 4,
      title: "Rate Limiting",
      description: "Prevent Attacks and Protect System Resources",
      icon: "/svgs/introduction/financial.svg",
      subTitle: "Rate Limiting Feature Includes",
      subDescription:
        "Maintain system performance and security by controlling the rate of requests from each user, blocking potential brute force and denial-of-service (DoS) attacks",
      subList: [
        "Request Frequency Control: Restricts the number of requests a user can make in a given timeframe, mitigating the risks of DoS and brute force attacks",
      ],
    },
    {
      id: 5,
      title: "Logging and Monitoring",
      description: "Stay Informed with Continuous Monitoring",
      icon: "/svgs/introduction/location.svg",
      subTitle: "Logging and Monitoring Features Include",
      subDescription:
        "Monitor system health and user activity with tools that enable quick detection of security threats and system issues, keeping your swim school management running smoothly",
      subList: [
        "AWS CloudWatch: Provides real-time monitoring of system activity to catch potential issues early",
        "In-App Audit Log: Offers a detailed log of all user actions within the application, supporting accountability and traceability",
        "Optional Tools: Integrate Grafana for data visualization, Slack Bot for immediate notifications, and Sentry for tracking application errors",
      ],
    },
    {
      id: 6,
      title: "Data Backup",
      description: "Safeguard Your Data with Reliable Backups",
      icon: "/svgs/introduction/communication.svg",
      subTitle: "Data Backup Feature Includes",
      subDescription:
        "Regular backups give you peace of mind, ensuring data can be restored in case of data loss, system failures, or accidental deletions",
      subList: [
        "Regular Backups: Provides frequent data backups, adding an additional layer of protection to recover data in case of any data loss",
      ],
    },
    {
      id: 7,
      title: "DDoS Protection",
      description: "Protect Your System from Disruptive Attacks",
      icon: "/svgs/introduction/resource.svg",
      subTitle: "DDoS Protection Feature Includes",
      subDescription:
        "Keep your swim school management system available and performing well with robust protection against distributed denial-of-service (DDoS) attacks",
      subList: [
        "Cloudflare DDoS Protection: Shields your system against DDoS attacks, maintaining service continuity and protecting system performance",
      ],
    },
  ];

  return (
    <>
      <div className="IntegrationNSecurity-introduction">
        <SlideUpOnScroll>
          <div className="IntegrationNSecurity-introduction--header">
            <div className="IntegrationNSecurity-introduction--header--description">
              Swim Sync provides a powerful platform that seamlessly integrates
              with your existing systems while ensuring the highest levels of
              security for your sensitive data. Our comprehensive integration
              capabilities and robust security measures allow you to streamline
              operations, enhance data flow, and protect your swim school's
              digital assets.
            </div>
          </div>
        </SlideUpOnScroll>
        <SlideUpOnScroll>
          <div className="IntegrationNSecurity-introduction--slider-wrapper">
            <Slider {...settings}>
              {imageData.map((item, key) => (
                <div
                  key={key}
                  className="IntegrationNSecurity-introduction--slider-wrapper--image"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </SlideUpOnScroll>
      </div>
      <div className="IntegrationNSecurity-introduction--featuresContainer">
        <div className="IntegrationNSecurity-introduction--featuresWrapper">
          {listFeatures.map((item, key) => (
            <div
              key={key}
              className="IntegrationNSecurity-introduction--featuresItem"
            >
              <div className="IntegrationNSecurity-introduction--featuresItemMain">
                <div className="IntegrationNSecurity-introduction--featuresItemLeft">
                  <img
                    src={item.icon}
                    alt=""
                    className="IntegrationNSecurity-introduction--featuresItemLeft--icon"
                  />
                  <div className="IntegrationNSecurity-introduction--featuresItemLeft--title">
                    {item.title}
                  </div>
                  <div className="IntegrationNSecurity-introduction--featuresItemLeft--description">
                    {item.description}
                  </div>
                  <p className="IntegrationNSecurity-introduction--featuresItemLeft--subDescription">
                    {item.subDescription}
                  </p>
                </div>
                <div className="IntegrationNSecurity-introduction--featuresItemRight">
                  <p className="IntegrationNSecurity-introduction--featuresItemRight--subTitle">
                    {item.subTitle}
                  </p>
                  <ul>
                    {item.subList.map((li, liIndex) => {
                      return <li key={liIndex}>{li}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Introduction;
