import SlideUpOnScroll from "components/SlideUpOnScroll";
import "./desktop.scss";

const DiveIntoSwimSync = () => {
  return (
    <section className="dive-into-swim-sync">
      <img src="/images/bg-shadow.png" alt="" />
      <div className="dive-into-swim-sync__wrapper">
        <div className="dive-into-swim-sync__header">
          <div className="dive-into-swim-sync__header__title">
            Dive into Swim Sync
          </div>
          <div className="dive-into-swim-sync__header__description">
            Tailored Solutions for Every Role
          </div>
        </div>
        <div className="dive-into-swim-sync__content">
          <SlideUpOnScroll threshold={0.1}>
            {" "}
            <div className="dive-into-swim-sync__content__item--wrapper">
              <div className="dive-into-swim-sync__content__item">
                <div className="item--header">
                  <div className="subTitle">For Swim School Owners</div>
                  <div className="title">Command Your Aquatic Empire</div>
                </div>
                <div className="item--description">
                  Imagine steering your swim school with the precision of an
                  Olympic coach. With Swim Sync, you're not just managing—you're
                  mastering your domain
                </div>
                <ul className="item--keys">
                  <li>
                    <strong>Centralized Command Center:</strong> Oversee all
                    operations from one intuitive dashboard. It's like having a
                    panoramic view of your entire aquatic empire.
                  </li>
                  <li>
                    <strong>Financial Clarity:</strong> Dive deep into your
                    finances with real-time reporting. Watch your revenue
                    streams flow as smoothly as a well-executed butterfly
                    stroke.
                  </li>
                  <li>
                    <strong>Multi-Location Mastery:</strong> Expand your reach
                    without drowning in complexity. Manage multiple locations as
                    effortlessly as a seasoned swimmer glides through water.
                  </li>
                </ul>
              </div>
            </div>
          </SlideUpOnScroll>
          <SlideUpOnScroll threshold={0.1}>
            <div className="dive-into-swim-sync__content__item--wrapper">
              <div className="dive-into-swim-sync__content__item">
                <div className="item--header">
                  <div className="subTitle">For Instructors</div>
                  <div className="title">Tailored Solutions for Every Role</div>
                </div>
                <div className="item--description">
                  Focus on creating the next generation of swimming champions
                  while Swim Sync handles the administrative waves.
                </div>
                <ul className="item--keys">
                  <li>
                    <strong>Effortless Roll Calls:</strong> Check in students
                    faster than they can say "cannonball!" Our smart attendance
                    system keeps you on deck, not behind a clipboard.
                  </li>
                  <li>
                    <strong>Progress Tracking That Makes Waves:</strong> Monitor
                    and record student achievements with a few taps. It's like
                    having a digital skills journal that never gets wet.
                  </li>
                  <li>
                    <strong>Schedule Surfing:</strong> Ride the waves of your
                    workday with ease. View and manage your schedule anytime,
                    anywhere—perfect for instructors always on the move.
                  </li>
                </ul>
              </div>
            </div>
          </SlideUpOnScroll>
          <SlideUpOnScroll threshold={0.2}>
            <div className="dive-into-swim-sync__content__item--wrapper">
              <div className="dive-into-swim-sync__content__item">
                <div className="item--header">
                  <div className="subTitle">For Parents</div>
                  <div className="title">Stay in the Loop, Out of the Pool</div>
                </div>
                <div className="item--description">
                  Keep your finger on the pulse of your child's swimming
                  journey, all from the convenience of your smartphone.
                </div>
                <ul className="item--keys">
                  <li>
                    <strong>Booking Brilliance:</strong> Secure spots in popular
                    classes faster than a sprint freestyle. Our intuitive
                    booking system ensures you never miss out on prime swim
                    times.
                  </li>
                  <li>
                    <strong>Progress Poolside Updates:</strong> Track your
                    little swimmer's progress as if you were right there on the
                    deck. Celebrate every new skill and milestone in real-time.
                  </li>
                  <li>
                    <strong>Crystal-Clear Communication:</strong> Connect with
                    instructors and staff as smoothly as water flows. Quick
                    messages, updates, and feedback—all without ever getting
                    your feet wet.
                  </li>
                </ul>
              </div>
            </div>
          </SlideUpOnScroll>
          <SlideUpOnScroll threshold={0.2}>
            <div className="dive-into-swim-sync__content__item custom">
              <div className="item--header">
                <div className="dot active"></div>
                <div className="dot"></div>
              </div>
              <div className="item--description">
                With Swim Sync, everyone plays a crucial role in creating an
                unparalleled swim school experience. Whether you're calling the
                shots, teaching the strokes, or cheering from the sidelines,
                we've got you covered. Dive in and discover how Swim Sync can
                transform your swim school journey!
              </div>
            </div>
          </SlideUpOnScroll>
        </div>
      </div>
    </section>
  );
};

export default DiveIntoSwimSync;
