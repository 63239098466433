import "./desktop.scss";
const OurImpact = () => {
  const data = [
    {
      id: 1,
      image: "/images/aboutUs/ourImpact/bg1.png",
      backgroundColor: "rgba(168, 255, 154, 1)",
      title: "Our Impact",
      content:
        "SwimSync is currently making waves with our partner swim schools across Thailand, revolutionizing how they operate and connect with families. We're proud of every minute saved on administration, every seamless booking made, and every parent who can now focus on their child's progress instead of paperwork.",
    },
    {
      id: 2,
      image: "/images/aboutUs/ourImpact/bg2.png",
      backgroundColor: "rgba(58, 218, 151, 1)",
      title: "Our Commitment",
      content:
        "We believe swim schools deserve technology that works as hard as they do. As parents, we understand the importance of swimming education. As tech experts, we're committed to providing the tools that make it easier to deliver that education. When swim schools can focus on teaching rather than administrative hassles, everyone wins.",
    },
    {
      id: 3,
      image: "/images/aboutUs/ourImpact/bg3.png",
      backgroundColor: "rgba(46, 182, 125, 1)",
      title: "Join Our Community",
      content:
        "Whether you're a small family-run program or a multi-location swim school, we invite you to be part of the SwimSync revolution. Together, we're bringing swim school management into the modern age, making life easier for swim schools and families alike.",
    },
  ];
  return (
    <div className="ourImpact">
      {data.map((item) => (
        <div
          key={item.id}
          className="ourImpact-item"
          style={{ backgroundColor: item.backgroundColor }}
        >
          <div className="ourImpact-item-image">
            <img src={item.image} alt="" />
          </div>
          <div className="ourImpact-item-title">{item.title}</div>
          <div className="ourImpact-item-description">{item.content}</div>
        </div>
      ))}
    </div>
  );
};

export default OurImpact;
