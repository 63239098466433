import SlideUpOnScroll from "components/SlideUpOnScroll";
import Footer from "components/footer";
import Header from "components/header/Header";
import SubmitTicketModal from "./SubmitTicketModal";
import { useState } from "react";
import "./desktop.scss";

enum Button {
  REQUEST_DEMO = "REQUEST_DEMO",
  BECOME_A_PARTNER = "BECOME_A_PARTNER",
  SUBMIT_A_TICKET = "SUBMIT_A_TICKET"
}

const data: Array<{
  image: string;
  title: string;
  description: string;
  button: {
    text: string;
    type: Button;
  };
}> = [
  {
    image: "/images/contact/stats.png",
    title: "Sales",
    description: "Contact our sales team for pricing or to request a demo",
    button: {
      text: "Request a Demo",
      type: Button.REQUEST_DEMO
    }
  },
  {
    image: "/images/contact/handshake.png",
    title: "Partnership",
    description: "Get in touch with our partner managers to learn more",
    button: {
      text: "Become a partner",
      type: Button.BECOME_A_PARTNER
    }
  },
  {
    image: "/images/contact/customer-service.png",
    title: "Technical Support",
    description: "Get assistance for technical issues or visit our Help center",
    button: {
      text: "Submit a ticket",
      type: Button.SUBMIT_A_TICKET
    }
  }
];

const Contact = () => {
  const [openSubmit, setOpenSubmit] = useState<boolean>(false);

  const handleClick = (type: Button) => {
    switch (type) {
      case Button.REQUEST_DEMO:
        window.Calendly.showPopupWidget(
          "https://calendly.com/swimsync-support/swim-sync-demo-booking"
        );
        return;
      case Button.BECOME_A_PARTNER:
        window.Calendly.showPopupWidget(
          "https://calendly.com/swimsync-support/swim-sync-demo-booking"
        );
        return;
      case Button.SUBMIT_A_TICKET:
        setOpenSubmit(true);
        return;
      default:
        return;
    }
  };

  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  return (
    <>
      <div className="app__wrapper">
        <div className="contactPage__headerBg"></div>
        <div className="contactPage__bg"></div>
        <Header />
        <main className="contactPage">
          <SlideUpOnScroll>
            <section className="contactPage__content">
              <div className="contactPage__content__info">
                <h2>We’d Love to Hear from You!</h2>
                <h1>Tools That Make Waves for Student Retention</h1>
                <h3>
                  Designed by Swim Experts Just for You: Transforming the Ideal
                  Swim School Experience. It’s Not Just About Enrollments; It’s
                  About Empowering Success with the Right Tools for Every Swim
                  Journey!
                </h3>
              </div>
              <div className="contactPage__content__list">
                {data.map((item, index: number) => {
                  return (
                    <div
                      key={index}
                      className="contactPage__content__list__item"
                    >
                      <div className="contactPage__content__list__item--image">
                        <img src={item.image} alt={item.image} />
                      </div>
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                      <button
                        className={index === 0 ? "requestDemo" : ""}
                        onClick={() => handleClick(item.button.type)}
                      >
                        {item.button.text}
                      </button>
                    </div>
                  );
                })}
              </div>
            </section>
          </SlideUpOnScroll>
          <section className="contactPage__section">
            <img src="/images/contact/section.svg" alt="section" />
          </section>
        </main>
        <SubmitTicketModal open={openSubmit} onClose={handleCloseSubmit} />
      </div>
      <Footer />
    </>
  );
};

export default Contact;
