import "./desktop.scss";

import { ChangeEvent, FC, InputHTMLAttributes, memo, useState } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string;
  animatedLabel?: boolean;
}

const AppCheckbox: FC<InputProps> = ({ label, name = label, ...rest }) => {
  const [state, setState] = useState({ value: "" });

  const handleChange = (event: ChangeEvent<{ value: string }>) => {
    setState({ value: event?.currentTarget?.value });
  };

  return (
    <>
      <div className="c__appCheckbox">
        <label>
          <input
            {...rest}
            type="checkbox"
            value={state.value}
            onChange={handleChange}
          />
          <div className="label"></div>
          <div>{label}</div>
          {rest.required ? <span className="requireTag">*</span> : null}
        </label>
      </div>
    </>
  );
};

export default memo(AppCheckbox);
