import "./desktop.scss";

import { memo } from "react";
import Header from "components/header/Header";
import Footer from "components/footer";
import Notice from "./components/notice";
import Form from "./components/form";

const PageInterested = () => {
  return (
    <>
      <div className="PageInterested">
        <Header />

        <div className="PageInterested-wrapper">
          <div className="content">
            <Notice />

            <Form />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default memo(PageInterested);
