import Header from "components/header/Header";
import "./desktop.scss";
import Footer from "components/footer";
import { useNavigate } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
const Resources = () => {
  const navigate = useNavigate();
  const swimSyncNews = [
    {
      title: "5 Ways to Improve Swim School Efficiency",
      image: <img src="/images/news-1.avif" alt="image-1" />,
      link: "/resources/ways-improve-swim-school-efficiency",
    },
    {
      title: "Maximizing Parent Engagement in Swim Schools",
      image: <img src="/images/news-2.avif" alt="image-2" />,
      link: "/resources/maximizing-parent-engagement",
    },
    {
      title: "The Impact of Technology on Swim Instruction",
      image: <img src="/images/news-3.avif" alt="image-3" />,
      link: "/resources/the-impact-of-technology-on-swim-instruction",
    },
    {
      title:
        "Why Water Safety Education Should Be at the Core of Every Swim School’s Mission",
      image: <img src="/images/news-4.png" alt="image-4" />,
      link: "/resources/why-water-safety-education-should-be-at-the-core-of-every-swim-schools-mission",
    },
    {
      title:
        "Creating a Seamless Swim School Experience: How to Make Every Lesson Count",
      image: <img src="/images/news-5.png" alt="image-5" />,
      link: "/resources/creating-a-seamless-swim-school-experience-how-to-make-every-lesson-count",
    },
  ];
  return (
    <div className="resourcesPage">
      <Header autoScrolled />
      <div className="resourcesPage-content">
        <h3>Resources</h3>
        <div className="special-news">
          <div className="special-news-item">
            <img
              src="/images/news-1.avif"
              alt="resources-img-1"
              className="resources-img"
            />
            <div className="special-news-item__content">
              <div className="special-news-item__content__title">
                5 Ways to Improve Swim School Efficiency
              </div>
              <div className="special-news-item__content__description">
                Running a swim school is like coordinating a synchronized swim
                routine—it takes planning, precision, and the right tools to
                flow smoothly. While having top-notch instructors and engaging
                lessons are vital, efficiency behind the scenes plays an equally
                important role in delivering a successful swim school
                experience.
              </div>
              <button
                className="btnDetails"
                onClick={() =>
                  navigate("/resources/ways-improve-swim-school-efficiency")
                }
              >
                <HiArrowNarrowRight />
              </button>
            </div>
          </div>
        </div>
        <div className="latest-news">
          <h3>Latest News</h3>
          <div className="latest-news-list">
            {swimSyncNews.map((item, key) => (
              <div className="latest-news-item" key={key}>
                <div className="image">{item?.image}</div>

                <div className="title">{item.title}</div>

                <button
                  className="btnDetails"
                  onClick={() => navigate(item.link)}
                >
                  <HiArrowNarrowRight />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Resources;
