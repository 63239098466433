import "./desktop.scss";

import Header from "components/header/Header";
import Footer from "components/footer";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import SwitchButton from "./components/SwitchButton";
import { memo, useCallback, useState } from "react";
// import Pricing from "./components/Pricing";
import PricingV2 from "./components/PricingV2";
import Compare from "./components/Compare";
import Testimonials from "components/Testimonials";
import Calculator from "./components/Calculator";
import FAQs from "./components/FAQs";

const PagePricing = () => {
  const [isToggle, setIsToggle] = useState(false);

  const handleToggle = useCallback(() => {
    setIsToggle((pre) => !pre);
  }, []);

  return (
    <>
      <div className="app__wrapper">
        {/* <img
          src="/images/header/bg-feature-header.png"
          alt=""
          className="app__wrapper--image"
        /> */}

        <div className="PagePricing_headerBg"></div>
        <div className="PagePricing_bg"></div>

        <Header />

        <div className="PagePricing-header-bg">
          <SlideUpOnScroll>
            <div className="PagePricing-header-wrapper">
              <div className="PagePricing-header-title">
                Scale Your Swim School
              </div>
              <div className="PagePricing-header-description">
                All plans include our full feature set. All features. One Simple
                plan. Zero compromise.
              </div>

              <SwitchButton
                value={isToggle}
                label={["Monthly", "Yearly"]}
                onChange={handleToggle}
              />

              {isToggle ? (
                <div className="note2">
                  Get more value: {" "}
                  <span className="note2Bold">15% off yearly plans</span>
                </div>
              ) : null}
            </div>
          </SlideUpOnScroll>

          <SlideUpOnScroll>
            <PricingV2 isToggle={isToggle} />
          </SlideUpOnScroll>

          <Compare isToggle={isToggle} />

          <SlideUpOnScroll>
            <Calculator isToggle={isToggle} onToggle={handleToggle} />
          </SlideUpOnScroll>

          <SlideUpOnScroll>
            <Testimonials />
          </SlideUpOnScroll>

          <SlideUpOnScroll>
            <FAQs />
          </SlideUpOnScroll>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default memo(PagePricing);
