import SlideUpOnScroll from "components/SlideUpOnScroll";
import "./desktop.scss";
import SlideDownOnScroll from "components/SlideDownOnScroll";
const OurJourneyTimeline = () => {
  return (
    <div className="OurJourneyTimeline">
      <h1>Our Journey Timeline</h1>
      <div className="OurJourneyTimeline-content">
        <div className="line"></div>

        <SlideUpOnScroll>
          <div className="OurJourneyTimeline-content-item first">
            <div className="card">
              <div className="text">Three parents meet at swim lessons</div>
              <div className="text">Initial concept development</div>
              <div className="text">First swim school partnerships formed</div>
            </div>
            <div className="year">2022</div>
          </div>
        </SlideUpOnScroll>
        <SlideDownOnScroll>
          <div className="OurJourneyTimeline-content-item second">
            <div className="card">
              <div className="text">Beta version launched</div>
              <div className="text">Intensive testing phase</div>
              <div className="text">Core features developed</div>
              <div className="text">First paying customers</div>
            </div>
            <div className="year">2023</div>
          </div>
        </SlideDownOnScroll>
        <SlideDownOnScroll>
          <div className="OurJourneyTimeline-content-item third">
            <div className="card">
              <div className="text">SwimSync V2 launch</div>
              <div className="text">Expanded feature set</div>
              <div className="text">Growing partner network</div>
              <div className="text">Progressive Web App release</div>
            </div>
            <div className="year">2024</div>
          </div>
        </SlideDownOnScroll>
      </div>
    </div>
  );
};

export default OurJourneyTimeline;
