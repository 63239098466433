import React, { useEffect, useRef, useState } from "react";
import "./Animation.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  threshold?: number;
}
const SlideDownOnScroll: React.FC<Props> = ({
  children,
  className,
  threshold = 0.45,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          // setIsVisible(false);
        }
      },
      { threshold: threshold }
    );

    const currentRef = elementRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [threshold]);

  return (
    <div
      ref={elementRef}
      className={`slide-down-container ${className} ${isVisible ? "slide-down" : ""}`}
    >
      {children}
    </div>
  );
};

export default SlideDownOnScroll;
