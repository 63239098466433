import { Routes, Route } from "react-router-dom";
import { publicIndexRoutes } from "./indexRoutes";

const PublicRoutes = () => {
  return (
    <Routes>
      {publicIndexRoutes.map((item, key) => {
        return <Route key={key} path={item.path} element={item.component} />;
      })}
    </Routes>
  );
};

export default PublicRoutes;
