import React, { useMemo } from 'react';
import './styles.scss';

interface IAppModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  keepMounted?: boolean;
  style?: React.CSSProperties;
}

const AppModal: React.FC<IAppModalProps> = ({
  open,
  onClose,
  children,
  keepMounted = false,
  style
}) => {
  const __isKeepMounted = useMemo((): string => {
    if (keepMounted) {
      if (open) {
        return 'c__modal-keepMounted-open';
      } else {
        return 'c__modal-keepMounted-close';
      }
    } else {
      return '';
    }
  }, [open, keepMounted]);

  if (!open && !keepMounted) return null;

  return (
    <div className={`c__modal ${__isKeepMounted}`} onClick={onClose}>
      <div
        className="c__modal-main"
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          event.stopPropagation()
        }
        style={style}
      >
        {children}
      </div>
    </div>
  );
};

export default AppModal;

// ============================================= //
interface IAppModalChildrenProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const AppModalContent: React.FC<IAppModalChildrenProps> = ({
  children,
  style
}) => {
  return (
    <div className="c__modal-content" style={style}>
      {children}
    </div>
  );
};
const AppModalTitle: React.FC<IAppModalChildrenProps> = ({
  children,
  style
}) => {
  return (
    <div className="c__modal-title" style={style}>
      {children}
    </div>
  );
};
const AppModalActions: React.FC<IAppModalChildrenProps> = ({
  children,
  style
}) => {
  return (
    <div className="c__modal-actions" style={style}>
      {children}
    </div>
  );
};

const AppModalFormContent: React.FC<IAppModalChildrenProps> = ({
  children,
  style
}) => {
  return (
    <div className="c__modal-formContent" style={style}>
      {children}
    </div>
  );
};
const AppModalFormTitle: React.FC<IAppModalChildrenProps> = ({
  children,
  style
}) => {
  return (
    <div className="c__modal-formTitle" style={style}>
      {children}
    </div>
  );
};
const AppModalFormActions: React.FC<IAppModalChildrenProps> = ({
  children,
  style
}) => {
  return (
    <div className="c__modal-formActions" style={style}>
      {children}
    </div>
  );
};

// ============================================= //
export {
  AppModalTitle,
  AppModalContent,
  AppModalActions,
  AppModalFormTitle,
  AppModalFormContent,
  AppModalFormActions
};
