import "./desktop.scss";

import AppCheckbox from "components/AppCheckbox";
import AppInput from "components/AppInput";
import { memo } from "react";

const staticQuest = [
  {
    question:
      "1. What are your main challenges with managing your swimming school?",
    options: [
      "Scheduling classes",
      "Managing staff",
      "Tracking student progress",
      "Handling payments",
      "Other (please specify)",
    ],
  },
  {
    question: "2. What features are you most interested in?",
    options: [
      "Class management",
      "Staff management",
      "Payment processing",
      "Parent/guardian communication",
      "Student progress tracking",
      "Reporting and analytics",
      "Other (please specify)",
    ],
  },
  {
    question:
      "3. Do you currently use any software to manage your swimming school?",
    options: ["Yes", "No"],
  },
  {
    question: "4. How soon are you looking to implement a new system?",
    options: [
      "Immediately",
      "Within the next month",
      "In the next 3-6 months",
      "Just exploring for now",
    ],
  },
  {
    question: "5. How did you hear about Swim Sync?",
    options: [
      "Referral",
      "Search engine",
      "Social media",
      "Other (please specify)",
    ],
  },
];

const form = () => {
  return (
    <div className="interested_form">
      <div className="inputList">
        <AppInput label="Full Name" required />
        <AppInput label="Mobile Number" required />
        <AppInput label="Email" required />
        <AppInput label="Swimming School Name" required />
        <AppInput label="Number of Students" />
      </div>

      {staticQuest?.map((q, key) => (
        <div key={key}>
          <div className="quest">{q.question}</div>

          <div className="options">
            {q.options?.map((o, key2) => (
              <div key={key2}>
                <AppCheckbox label={o} />
              </div>
            ))}
          </div>
        </div>
      ))}


      <button className="btnConfirm">Confirm</button>
    </div>
  );
};

export default memo(form);
