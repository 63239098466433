import SlideDownOnScroll from "components/SlideDownOnScroll";
import "./desktop.scss";

const OurTeam = () => {
  const members = [
    {
      id: 1,
      name: "Oscar",
      role: "Co-Founder & Tech Lead",
      description:
        "Parent of two water babies and coding enthusiast who believes technology should simplify, not complicate",
    },
    {
      id: 2,
      name: "Maricar",
      role: "Co-Founder & Product Lead",
      description:
        "Former UX designer turned swim parent who's passionate about creating intuitive user experiences",
    },
    {
      id: 3,
      name: "Moses",
      role: "Co-Founder & Operations Lead",
      description:
        "Tech veteran and dad who believes in the power of automation to give time back to teachers",
    },
  ];
  return (
    <div className="ourTeam">
      <h1>OUR TEAM</h1>
      <SlideDownOnScroll>
        <div className="ourTeam-members">
          {members.map((member) => (
            <div className="ourTeam-members-item" key={member.id}>
              <div className="ourTeam-members-item-name">{member.name}</div>
              <div className="ourTeam-members-item-role">{member.role}</div>
              <div className="ourTeam-members-item-description">
                {member.description}
              </div>
            </div>
          ))}
        </div>
      </SlideDownOnScroll>
      <div className="ourTeam-footer">
        Plus our growing team of developers, designers, and support specialists
        who share our vision of better swim school management.
      </div>
    </div>
  );
};

export default OurTeam;
