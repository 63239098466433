import { HiPlus } from "react-icons/hi";
import "./desktop.scss";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import { Fragment } from "react/jsx-runtime";
import { HiMinus } from "react-icons/hi2";

const FAQs = () => {
  const listFAQs = [
    {
      question: "Is Swim Sync suitable for swim schools of all sizes?",
      answer: `Absolutely! Whether you're teaching tadpoles or training future Olympians, Swim Sync is your perfect partner. Our flexible platform adapts to schools of all sizes. Small schools love our user-friendly interface and essential features, while larger institutions dive deep into our advanced functionalities like multi-location management and comprehensive analytics. No matter your size, we'll help you make a splash!"`,
    },
    {
      question: "How does Swim Sync keep our data safe??",
      answer: `At Swim Sync, we treat your data like it's wearing a life jacket – always protected! We use top-notch encryption protocols and secure servers to keep your information as safe as a fish in water. With regular security audits and strict compliance with data protection regulations, you can focus on teaching while we handle the digital lifeguarding.`,
    },
    {
      question: "Can we bring our existing data into Swim Sync?",
      answer: `Absolutely! Think of it as giving your data swimming lessons to join the Swim Sync pool. Our dedicated support team will help you migrate all your important information, ensuring a smooth transition that's as easy as floating on your back. We'll have you up and swimming in no time!`,
    },
    {
      question: "What kind of support can we expect from Swim Sync?",
      answer: `We're like your personal swim coach – always here when you need us! Our support team is available via email, phone, or chat to help you navigate any waves. Plus, we've got a treasure trove of resources including tutorials, FAQs, and training materials. It's like having a full support crew on the pool deck, cheering you on!`,
    },
    {
      question: "How customizable is Swim Sync?",
      answer:
        "As customizable as your favorite swim cap! Swim Sync adapts to fit your school like a perfect swimsuit. Tailor features such as student progress tracking, class FAQs 2 schedules, and communication tools to match your unique teaching style. It's your pool, we just help you manage it better!",
    },
    {
      question: "How does Swim Sync enhance parent engagement?",
      answer:
        "Our Member Portal is like a digital poolside for parents! They can easily enroll their little swimmers, track progress, chat with instructors, and stay updated on all the splashy news. It's all about creating waves of engagement and keeping everyone in the swim of things!",
    },
    {
      question: "Can Swim Sync handle our billing and payment processing?",
      answer:
        "You bet! Swim Sync turns the tide on complex financials. We offer automated payment handling smoother than a streamlined butterfly stroke. Parents can set up recurring payments, while you manage invoices and track financial performance with the ease of a perfect backstroke. It's all about keeping your finances afloat without the struggle!",
    },
    {
      question:
        "Does Swim Sync offer features for managing instructors and staff?",
      answer:
        "Absolutely! Our Staff Portal is like a digital locker room for your team. Easily manage schedules, track certifications, and communicate with your aquatic dream team. It's all about keeping your staff synchronized, just like a well-choreographed water ballet!",
    },
    {
      question:
        "How does Swim Sync help with class scheduling and pool management?",
      answer:
        "Swim Sync turns complex scheduling into a synchronised swimming routine! Our intuitive interface lets you manage multiple classes, instructors, and pool spaces with ease. You can visualize your entire schedule at a glance, making sure every lane is optimized and every minute counts. It's like having a bird's eye view of your aquatic empire!",
    },
    {
      question:
        "Can Swim Sync help us track student progress and achievements?",
      answer:
        "Indeed! Think of it as a digital report card that never gets wet. Our progress tracking features let you monitor each student's journey from doggy paddle to butterfly stroke. Set milestones, record achievements, and share progress reports with parents. It's all about celebrating every splash towards success!",
    },
    {
      question: "How often does Swim Sync update its features?",
      answer:
        "We're always riding the wave of innovation! Our development team regularly rolls out updates and new features based on user feedback and industry trends. It's FAQs 3 like giving your management system swimming lessons – it just keeps getting better and better!",
    },
    {
      question: "Is there a mobile app for Swim Sync?",
      answer:
        "You bet! Our Progressive Web App (PWA) is like having a pool in your pocket. Access schedules, manage bookings, and communicate with parents and staff on the go. Whether you're poolside or beachside, Swim Sync keeps you connected to your aquatic business.",
    },
  ];
  return (
    <SlideUpOnScroll threshold={0.1}>
      <div className="FAQs">
        <div className="FAQs__wrapper">
          <div className="FAQs__header">
            <h2 className="FAQs__header__title">FAQs</h2>
          </div>
          <div className="FAQs__list">
            {listFAQs?.map((faq, key) => (
              <Fragment key={key}>
                <input id={`${key}`} type="checkbox" />
                <label htmlFor={`${key}`}>
                  <div className="FAQs__list__item">
                    <h3 className="FAQs__list__item-title cursor">
                      {faq?.question}
                    </h3>
                    <HiPlus className="cursor plus" size={20} />
                    <HiMinus className="cursor minus" size={20} />
                  </div>
                  <p className="FAQs__answer">{faq?.answer}</p>
                </label>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </SlideUpOnScroll>
  );
};
export default FAQs;
