import "./desktop.scss";

import { ChangeEvent, FC, InputHTMLAttributes, memo, useState } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string;
  animatedLabel?: boolean;
}

const AppInput: FC<InputProps> = ({
  label,
  name = label,
  animatedLabel = true,
  ...rest
}) => {
  const [state, setState] = useState({ value: "" });

  const handleChange = (event: ChangeEvent<{ value: string }>) => {
    setState({ value: event?.currentTarget?.value });
  };

  return (
    <>
      <div className="c__appInput">
        <input
          {...rest}
          id={name}
          type="text"
          required={animatedLabel}
          value={state.value}
          onChange={handleChange}
        />
        <label htmlFor={name}>
          {label}
          {rest.required ? <span className="requireTag">*</span> : null}
        </label>
      </div>
    </>
  );
};

export default memo(AppInput);
