import { motion } from "framer-motion";

interface Props {
  bgColor1: string;
  bgColor2: string;

  bgWidth1: string;
  bgWidth2: string;

  x1: string;
  y1: string;

  x2: string;
  y2: string;
}
const BackgroundAnimation: React.FC<Props> = ({
  bgColor1,
  bgColor2,
  bgWidth1,
  bgWidth2,
  x1,
  y1,
  x2,
  y2,
}: Props) => {
  return (
    <>
      {/* Background Image 1 */}
      <motion.div
        animate={{
          x: ["-25%", x1, "-5%", x1, "-25%"], // Moves from left to right
          y: ["10%", y1, "55%", y1, "10%"], // Bounces up and down
          rotate: [0, 45, 5, 45, 0], // Rotates as it moves
          scale: [1, 0.8, 1],
        }}
        transition={{
          duration: 30,
          ease: "easeInOut",
          repeat: Infinity,
        }}
        style={{
          opacity: 0.3,
          zIndex: -1,
          transformOrigin: "center",
          height: "100%",
          width: "50%",
          right: 0,
          position: "absolute",
          top: -10,
        }}
      >
        <div
          className="square1"
          style={{
            backgroundColor: bgColor1,
            width: bgWidth1,
            height: bgWidth1,
          }}
        ></div>
      </motion.div>

      {/* Background Image 2 */}
      <motion.div
        animate={{
          x: ["0%", x2, "5%", x2, "0%"], // Moves from left to right
          y: ["-5%", y2, "-15%", y2, "-5%"], // Bounces up and down
          rotate: [0, 10, 45, 10, 0], // Rotates as it moves
          scale: [0.8, 1, 0.8],
        }}
        transition={{
          duration: 30, // Duration for the entire animation cycle
          ease: "easeInOut",
          repeat: Infinity,
        }}
        style={{
          opacity: 0.3,
          zIndex: -1,
          transformOrigin: "center",
          height: "100%",
          width: "50%",
          right: 0,
          position: "absolute",
        }}
      >
        <div
          className="circle2"
          style={{
            backgroundColor: bgColor2,
            width: bgWidth2,
            height: bgWidth2,
          }}
        ></div>
      </motion.div>
    </>
  );
};

export default BackgroundAnimation;
