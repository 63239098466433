import "./desktop.scss";

import AppTooltip from "components/AppToolTip";
import { memo } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

interface Props {
  isToggle?: boolean;
}

const Pricing = ({ isToggle }: Props) => {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      title: "One Simple Price",
      price: "$299",
      subPrice: "/mo/location",
      note: "All features included. No hidden costs.",
      btnText: "Get Started with SwimSync today",
    },
  ];

  const dataYearly = [
    {
      id: 1,
      title: "One Simple Price",
      price: "$3,049",
      subPrice: "/yr/location",
      note: "All features included. No hidden costs.",
      btnText: "Get Started with SwimSync today",
    },
  ];

  return (
    <>
      <div className="pagePricing_pricing slider-container">
        <div className="pagePricing_pricing__wrapper__itemBlank"></div>
        {(isToggle ? dataYearly : data).map((item, key) => (
          <div className="pagePricing_pricing__wrapper__item" key={key}>
            <div className="pagePricing_pricing__wrapper__item__title">
              {item.title}
            </div>

            <div className="pagePricing_pricing__wrapper__item__price">
              {item.price}{" "}
              <span className="pagePricing_pricing__wrapper__item__subPrice">
                {item.subPrice}
              </span>
            </div>

            <div className="pagePricing_pricing__wrapper__item__note">
              {item.note}
            </div>

            {item?.btnText ? (
              <div className="pagePricing_pricing__wrapper__item__button">
                <button
                  className="text"
                  onClick={() => navigate("/interested")}>
                  {item.btnText}
                </button>
              </div>
            ) : null}
          </div>
        ))}
        <div className="pagePricing_pricing__wrapper__itemBlank"></div>
      </div>

      <div className="pagePricing_pricing__footer">
        <div className="text1">
          All our plans have a one-time setup fee:{" "}
          <span className="highlight1">$899 per location</span>{" "}
          <AppTooltip
            tooltipText={
              <div className="tt_text">
                Our comprehensive setup process includes:
                <ul>
                  <li>Software installation and configuration</li>
                  <li>Custom branding setup Integration with your </li>
                  <li>Integration with your existing tools</li>
                  <li>Initial staff training session</li>
                  <li>Personalized onboarding support</li>
                </ul>
              </div>
            }
            position="top"
            typeToolTipText="max">
            <HiOutlineExclamationCircle size={20} />
          </AppTooltip>
        </div>

        <div className="text2">
          Ensuring a smooth start for your swim school
        </div>
      </div>
    </>
  );
};

export default memo(Pricing);
