import { memo, useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { NavLink } from "react-router-dom";

import "./mobile.scss";

interface SidebarItemProps {
  isActive?: boolean;
  onOpenLogoutModal?: () => void;
  onClick?: () => void;
  item: any;
}

const SidebarItem = ({ item, isActive, onClick }: SidebarItemProps) => {
  const [open, setOpen] = useState(false);

  if (item.children) {
    return (
      <div
        className={`sidebar__menu ${isActive && "sidebar__menu--active"} ${
          open ? "sidebar__menu--active--open" : ""
        }`}>
        <div
          className={`sidebar__menu__title ${
            isActive && "sidebar__menu__title--active"
          } ${open && "sidebar__menu__title--open"}`}
          onClick={() => setOpen(!open)}>
          {item.title}
          <HiOutlineChevronDown />
        </div>
        <div className="sidebar__menu__child">
          {item.children.map((child: any, index: number) => (
            <SidebarItem key={index} item={child} onClick={onClick} />
          ))}
        </div>
      </div>
    );
  } else {
    if (item.title === "Logout")
      return (
        <>
          <button
            className={`sidebar__menu ${
              isActive && "sidebar__menu--active"
            } sidebar__menu__title`}>
            {item.icon}
            {item.title}
          </button>
        </>
      );

    return (
      <NavLink
        to={item.path || "#"}
        className={({ isActive }) =>
          `sidebar__menu ${
            isActive && "sidebar__menu--active"
          } sidebar__menu__title`
        }
        onClick={onClick}>
        {item.icon}
        {item.title}
      </NavLink>
    );
  }
};

export default memo(SidebarItem);
