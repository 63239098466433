import "./desktop.scss";

const BookDemo = () => {
  return (
    <div className="ReportNAnalytics-book-demo">
      <div className="ReportNAnalytics-book-demo--wrapper">
        <div className="ReportNAnalytics-book-demo--title">
          Ready to harness the power of data for your swim school's success?
          <br />
          Dive into Swim Sync's reporting and analytics tools today!
        </div>
        <div className="ReportNAnalytics-book-demo--buttonWrapper">
          <button
            className="ReportNAnalytics-book-demo--button"
            onClick={() => {
              // Mở widget khi nhấn nút
              window.Calendly.showPopupWidget(
                "https://calendly.com/swimsync-support/swim-sync-demo-booking"
              );
            }}
          >
            Schedule Demo
          </button>
          {/* <button
            className="ReportNAnalytics-book-demo--button"
            onClick={() => navigate("/resources")}
          >
            Learn More
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default BookDemo;
