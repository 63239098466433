import { motion } from "framer-motion";

const BackgroundAnimation = () => {
  return (
    <>
      {/* Background Image 1 */}
      <motion.div
        animate={{
          x: ["-25%", "-15%", "-5%", "-15%", "-25%"], // Moves from left to right
          y: ["10%", "-25%", "5%", "-25%", "10%"], // Bounces up and down
          rotate: [0, 45, 5, 45, 0], // Rotates as it moves
          scale: [1, 1],
        }}
        transition={{
          duration: 30, 
          ease: "easeInOut",
          repeat: Infinity,
        }}
        style={{
          opacity: 0.3,
          zIndex: -1,
          transformOrigin: "center",
          height: "100%",
          width: "50%",
          right: 0,
          position: "absolute",
          top: -10,
        }}
      >
        <div className="circle1"></div>
        <div className="circle2"></div>
      </motion.div>

      {/* Background Image 2 */}
      <motion.div
        animate={{
          x: ["0%", "-20%", "5%", "-20%", "0%"], // Moves from left to right
          y: ["-5%", "0%", "-15%", "0%", "-5%"], // Bounces up and down
          rotate: [0, 10, 45, 10, 0], // Rotates as it moves
          scale: [0.8, 1, 0.8],
        }}
        transition={{
          duration: 30, // Duration for the entire animation cycle
          ease: "easeInOut",
          repeat: Infinity,
        }}
        style={{
          opacity: 0.3,
          zIndex: -1,
          transformOrigin: "center",
          height: "100%",
          width: "50%",
          right: 0,
          position: "absolute",
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="500 -200 800 800">
          <path
            fill="red"
            d="M740.199 -141.7L1209.73 -33.2747C1240.97 -26.0606 1260.44 5.10148 1253.22 36.3415L1144.8 505.868C1133.17 556.223 1066.43 567.428 1039.02 523.561L677.918 -54.3902C650.51 -98.2564 689.798 -153.338 740.199 -141.7Z"
          ></path>
        </svg>
      </motion.div>
    </>
  );
};

export default BackgroundAnimation;
