import FeatureStaffPortal from "pages/features/StaffPortal";
import FeatureMemberPortal from "pages/features/MemberPortal";
import AdministrativeTools from "pages/features/AdministrativeTools";
import Pricing from "pages/pricing";
import { IRoute } from "../interfaces";
import Home from "pages/home";
import IntegrationNSecurity from "pages/features/IntegrationNSecurity";
import DataPrivacy from "pages/dataPrivacy";
import TermCondition from "pages/termCondition";
import Interested from "pages/interested";
import Resources from "pages/resources";
import WaysImprove from "pages/resources/blogs/WaysImprove";
import MaximizingParentEngagement from "pages/resources/blogs/MaximizingParentEngagement";
import ImpactOfTechnology from "pages/resources/blogs/ImpactOfTechnology";
import WaterSafetyEducation from "pages/resources/blogs/WaterSafetyEducation";
import SeamlessSwimSchool from "pages/resources/blogs/SeamlessSwimSchool";
import ReportNAnalytics from "pages/features/ReportNAnalytics";
import Contact from "pages/contact";
import About from "pages/about";

export const publicIndexRoutes: IRoute[] = [
  {
    path: "/",
    component: <Home />,
    isPublic: true
  },
  {
    path: "*",
    component: <Home />,
    isPublic: true
  },
  {
    path: "/feature/staff",
    component: <FeatureStaffPortal />,
    isPublic: true
  },
  {
    path: "/feature/member",
    component: <FeatureMemberPortal />,
    isPublic: true
  },
  {
    path: "/feature/administrative-tools",
    component: <AdministrativeTools />,
    isPublic: true
  },
  {
    path: "/feature/reporting-and-analytics",
    component: <ReportNAnalytics />,
    isPublic: true
  },
  {
    path: "/feature/integration-and-security",
    component: <IntegrationNSecurity />,
    isPublic: true
  },
  {
    path: "/pricing",
    component: <Pricing />,
    isPublic: true
  },
  {
    path: "/about",
    component: <About />,
    isPublic: true
  },
  {
    path: "/contact",
    component: <Contact />,
    isPublic: true
  },
  {
    path: "/interested",
    component: <Interested />,
    isPublic: true
  },
  {
    path: "/data-privacy",
    component: <DataPrivacy />,
    isPublic: true
  },
  {
    path: "/terms-and-conditions",
    component: <TermCondition />,
    isPublic: true
  },
  {
    path: "/resources",
    component: <Resources />,
    isPublic: true
  },
  {
    path: "/resources/ways-improve-swim-school-efficiency",
    component: <WaysImprove />,
    isPublic: true
  },
  {
    path: "/resources/maximizing-parent-engagement",
    component: <MaximizingParentEngagement />,
    isPublic: true
  },
  {
    path: "/resources/the-impact-of-technology-on-swim-instruction",
    component: <ImpactOfTechnology />,
    isPublic: true
  },
  {
    path: "/resources/why-water-safety-education-should-be-at-the-core-of-every-swim-schools-mission",
    component: <WaterSafetyEducation />,
    isPublic: true
  },
  {
    path: "/resources/creating-a-seamless-swim-school-experience-how-to-make-every-lesson-count",
    component: <SeamlessSwimSchool />,
    isPublic: true
  }
];
