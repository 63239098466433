import React from "react";
import "./desktop.scss";
import { FiPlayCircle } from "react-icons/fi";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import ImageAnimation from "./ImageAnimation";
import BackgroundAnimation from "./BackgroundAnimation";
const BookDemo = () => {
  return (
    <>
      <div className="bookDemo">
        <div className="bookDemo__content">
          <div className="bookDemo__content-left">
            <SlideUpOnScroll>
              <div className="text">
                <h4 className="bookDemo__content-left--title">
                  Revolutionise Your Swim School Management
                </h4>
                <p className="bookDemo__content-left--description">
                  Streamline operations, enhance parent engagement, and focus on
                  what matters most - teaching swimming.
                </p>
              </div>
              <div className="actions">
                <button
                  className="actions-bookDemo"
                  onClick={() => {
                    // Mở widget khi nhấn nút
                    window.Calendly.showPopupWidget(
                      "https://calendly.com/swimsync-support/swim-sync-demo-booking"
                    );
                  }}
                >
                  Book a Demo
                </button>
                <button className="actions-watchDemo">
                  <FiPlayCircle size={20} /> Watch Demo Video
                </button>
              </div>
            </SlideUpOnScroll>
          </div>
          <div className="bookDemo__content-right">
            <ImageAnimation />
          </div>
        </div>

        <div className="bookDemo__content-background">
          <BackgroundAnimation />
        </div>
      </div>
      <div className="l-shaped-line">
        <div className="l-shaped-line-wrapper">
          <div className="curve"></div>
          <div className="l-shaped-line-small"></div>
        </div>
      </div>
      <div className="diver-line">
        <div className="curve"></div>
      </div>
      <div className="l-shaped-line-right">
        <div className="l-shaped-line-right-wrapper">
          <div className="curve"></div>
          <div className="l-shaped-line-right-small"></div>
        </div>
      </div>

      <div className="diver-line-right">
        <div className="curve"></div>
      </div>
      <div className="bookDemo__logo">
        <div className="bookDemo__logo--image">
          <img src="/images/bookDemo/favicon.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default BookDemo;
