import "./desktop.scss";
const BookDemo = () => {
  return (
    <div className="FeatureStaffPortal-book-demo">
      <div className="FeatureStaffPortal-book-demo--wrapper">
        <div className="FeatureStaffPortal-book-demo--title">
          Ready to elevate your swim school management to Olympic levels?
          Experience the Swim Sync difference today!
        </div>
        <button
          className="FeatureStaffPortal-book-demo--button"
          onClick={() => {
            // Mở widget khi nhấn nút
            window.Calendly.showPopupWidget(
              "https://calendly.com/swimsync-support/swim-sync-demo-booking"
            );
          }}
        >
          Book a Demo
        </button>
      </div>
    </div>
  );
};

export default BookDemo;
