import { memo } from "react";

import "./desktop.scss";
import "./mobile.scss";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import { useNavigate } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";

const News = () => {
  const navigate = useNavigate();
  const swimSyncNews = [
    {
      title: "5 Ways to Improve Swim School Efficiency",
      image: <img src="/images/news-1.avif" alt="image-1" />,
      link: "/resources/ways-improve-swim-school-efficiency",
    },
    {
      title: "Maximizing Parent Engagement in Swim Schools",
      image: <img src="/images/news-2.avif" alt="image-2" />,
      link: "/resources/maximizing-parent-engagement",
    },
    {
      title: "The Impact of Technology on Swim Instruction",
      image: <img src="/images/news-3.avif" alt="image-3" />,
      link: "/resources/the-impact-of-technology-on-swim-instruction",
    },
  ];

  return (
    <>
      <div className="news_wrap" id="news">
        <div className="news">
          <div className="title_2">Resources</div>

          <SlideUpOnScroll threshold={0.22}>
            <div className="news-content">
              {swimSyncNews.map((item, key) => (
                <div className="card" key={key}>
                  <div className="image">{item?.image}</div>

                  <div className="title">{item.title}</div>

                  <button
                    className="btnDetails"
                    onClick={() => navigate(item.link)}
                  >
                    <HiArrowNarrowRight />
                  </button>
                </div>
              ))}
            </div>
          </SlideUpOnScroll>
          <div className="action" onClick={() => navigate("/resources")}>
            <button>Show more</button>
          </div>
        </div>
      </div>
      <div className="bookADemo">
        <div className="bookADemo__wrapper">
          <div className="bookADemo__title">
            Ready to dive into effortless swim school management?
          </div>
          <div className="empty"></div>
        </div>
        <SlideUpOnScroll className="bookADemo__action" threshold={1}>
          <div className="bookADemo__action__title">
            Book a Demo with One of Our Customer Success Legends!
          </div>
          <button
            className="bookADemo__action__btn"
            onClick={() => {
              // Mở widget khi nhấn nút
              window.Calendly.showPopupWidget(
                "https://calendly.com/swimsync-support/swim-sync-demo-booking"
              );
            }}
          >
            Book a Demo
          </button>
        </SlideUpOnScroll>
      </div>
    </>
  );
};
export default memo(News);
