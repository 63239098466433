import "./desktop.scss";

import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  tooltipText: ReactNode | string;
  position?: "top" | "bottom" | "left" | "right";
  typeToolTipText?: "max" | "fit";
}

const AppTooltip: FC<Props> = ({
  children,
  position = "top",
  tooltipText = "Tooltip Text",
  typeToolTipText = "max",
}) => {
  return (
    <div className="tooltip-trigger">
      {children}
      <div className={`tooltip tooltip-${position}`}>
        <div className={`tooltip-text typeToolTipText-${typeToolTipText}`}>
          {tooltipText}
        </div>
      </div>
    </div>
  );
};

export default AppTooltip;
