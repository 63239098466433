import { useNavigate } from "react-router-dom";
import "./desktop.scss";
import Slider from "react-slick";

const Pricing = () => {
  const navigate = useNavigate();
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 6.65,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const data = [
    {
      id: 1,
      image: "/images/pricing/levels/1.png",
    },
    {
      id: 2,
      image: "/images/pricing/levels/2.png",
    },
    {
      id: 3,
      image: "/images/pricing/levels/3.png",
    },
    {
      id: 4,
      image: "/images/pricing/levels/1.png",
    },
    {
      id: 5,
      image: "/images/pricing/levels/2.png",
    },
    {
      id: 6,
      image: "/images/pricing/levels/3.png",
    },
    {
      id: 7,
      image: "/images/pricing/levels/1.png",
    },
    {
      id: 8,
      image: "/images/pricing/levels/2.png",
    },
    {
      id: 9,
      image: "/images/pricing/levels/3.png",
    },
  ];

  return (
    <div className="pricing ">
      <img
        src="/images/pricing/header-left.png"
        className="bg-img header-left"
        alt=""
      />
      <img
        src="/images/pricing/header-left1.png"
        className="bg-img header-left1"
        alt=""
      />
      <img
        src="/images/pricing/header-left2.png"
        className="bg-img header-left2"
        alt=""
      />
      <img
        src="/images/pricing/header-center.png"
        className="bg-img header-center"
        alt=""
      />
      <img
        src="/images/pricing/header-right.png"
        className="bg-img header-right"
        alt=""
      />
      <img src="/images/pricing/center.png" className="bg-img center" alt="" />
      <img
        src="/images/pricing/wave-footer.png"
        className="bg-img wave"
        alt=""
      />
      <img
        src="/images/pricing/wave-footer1.png"
        className="bg-img wave1"
        alt=""
      />
      <div className="pricing-content-wrapper">
        <div className="pricing-content">
          <div className="pricing-content-info">
            <div className="pricing-content-info-item-left">
              <div className="item-left-title">Scale as you grow.</div>
              <div
                className="item-left-button"
                onClick={() => navigate("/pricing")}
              >
                Explore Our Plan
              </div>
            </div>
            <div className="pricing-content-info-item-right">
              <div className="item-right-title">One Simple Price</div>
              <div className="item-right-price">
                $299
                <span>/mo/location</span>
              </div>
              <div className="item-right-description">
                All features included. No hidden costs.
              </div>
            </div>
          </div>
          <div className="pricing-content-info ">
            <div className="pricing-content-info-item2-left">
              <div className="wrapper1">
                <div className="item-left-title">
                  Everything you need to run your Swim School
                </div>
                <div className="item-left-button">Talk to sales</div>
              </div>
              <div className="wrapper2">
                <div className="title">Need help choosing?</div>
                <div className="help-actions">
                  <img src="/images/pricing/button-chat.png" alt="" />
                  <img src="/images/pricing/button-call.png" alt="" />
                </div>
              </div>
            </div>
            <div className="pricing-content-info-item2-right">
              <div className="item">30+ features</div>
              <div className="item">Unlimited Students</div>
              <div className="item">No hidden costs</div>
            </div>
          </div>
        </div>
        <div className="pricing-slider">
          <Slider {...settings}>
            {data?.map((item, key) => (
              <div key={key}>
                <img src={item?.image} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
