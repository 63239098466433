import "./desktop.scss";

import { memo } from "react";

interface Props {
  value: boolean;
  label?: Array<string>;
  onChange?: () => void;
}

const SwitchButton: React.FC<Props> = ({
  value,
  label = ["NO", "YES"],
  onChange,
}) => {
  return (
    <>
      <div className="c__switchButton">
        <label className="c__switchButton_switch">
          <input
            className="c__switchButton_input"
            type="checkbox"
            checked={value}
            onChange={onChange}
          />
          <span className="c__switchButton_slider"></span>

          <div
            className={`c__switchButton_label ${value ? "active" : "inactive"}`}>
            <div className="c__switchButton_label_no">{label?.[0]}</div>
            <div className="c__switchButton_label_yes">{label?.[1]}</div>
          </div>
        </label>
      </div>
    </>
  );
};

export default memo(SwitchButton);
