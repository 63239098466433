import ScrollToTop from "components/SrollToTop";
import "./App.css";
import "./App.scss";
import "./AppMobile.scss";

import RouteList from "routes";

function App() {
  return (
    <div className="app">
      <ScrollToTop />
      <RouteList />
    </div>
  );
}

export default App;
