const ListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none">
      <path
        d="M9 10H19C19.2652 10 19.5196 9.89464 19.7071 9.70711C19.8946 9.51957 20 9.26522 20 9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10Z"
        fill="#2EB67D"
      />
      <path
        d="M9 15H19C19.2652 15 19.5196 14.8946 19.7071 14.7071C19.8946 14.5196 20 14.2652 20 14C20 13.7348 19.8946 13.4804 19.7071 13.2929C19.5196 13.1054 19.2652 13 19 13H9C8.73478 13 8.48043 13.1054 8.29289 13.2929C8.10536 13.4804 8 13.7348 8 14C8 14.2652 8.10536 14.5196 8.29289 14.7071C8.48043 14.8946 8.73478 15 9 15Z"
        fill="#2EB67D"
      />
      <path
        d="M11 18H9C8.73478 18 8.48043 18.1054 8.29289 18.2929C8.10536 18.4804 8 18.7348 8 19C8 19.2652 8.10536 19.5196 8.29289 19.7071C8.48043 19.8946 8.73478 20 9 20H11C11.2652 20 11.5196 19.8946 11.7071 19.7071C11.8946 19.5196 12 19.2652 12 19C12 18.7348 11.8946 18.4804 11.7071 18.2929C11.5196 18.1054 11.2652 18 11 18Z"
        fill="#2EB67D"
      />
      <path
        d="M25 16.872V4C25 3.20435 24.6839 2.44129 24.1213 1.87868C23.5587 1.31607 22.7956 1 22 1H6C5.20435 1 4.44129 1.31607 3.87868 1.87868C3.31607 2.44129 3 3.20435 3 4V26C3 26.7956 3.31607 27.5587 3.87868 28.1213C4.44129 28.6839 5.20435 29 6 29H16.418C17.2007 29.7248 18.1292 30.2741 19.1413 30.6111C20.1534 30.9482 21.2258 31.0653 22.2868 30.9546C23.3478 30.8438 24.3729 30.5078 25.2936 29.969C26.2142 29.4302 27.0093 28.701 27.6255 27.8302C28.2416 26.9594 28.6648 25.9671 28.8666 24.9196C29.0684 23.8721 29.0442 22.7936 28.7957 21.7562C28.5472 20.7188 28.08 19.7465 27.4254 18.9041C26.7708 18.0618 25.9439 17.369 25 16.872ZM5 26V4C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 3 6 3H22C22.2652 3 22.5196 3.10536 22.7071 3.29289C22.8946 3.48043 23 3.73478 23 4V16.151C21.608 15.8655 20.1635 15.9816 18.835 16.4859C17.5066 16.9901 16.3488 17.8617 15.4968 18.9989C14.6448 20.1361 14.1337 21.4921 14.0231 22.9088C13.9124 24.3254 14.2069 25.7443 14.872 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26ZM21.5 29C20.4122 29 19.3488 28.6774 18.4444 28.0731C17.5399 27.4687 16.8349 26.6098 16.4187 25.6048C16.0024 24.5998 15.8935 23.4939 16.1057 22.427C16.3179 21.3601 16.8417 20.3801 17.6109 19.6109C18.3801 18.8417 19.3601 18.3179 20.427 18.1057C21.4939 17.8935 22.5998 18.0024 23.6048 18.4187C24.6098 18.8349 25.4687 19.5399 26.0731 20.4444C26.6774 21.3488 27 22.4122 27 23.5C26.9984 24.9582 26.4184 26.3562 25.3873 27.3873C24.3562 28.4184 22.9582 28.9984 21.5 29Z"
        fill="#2EB67D"
      />
      <path
        d="M23.1994 21.4L20.8914 24.478L19.7064 23.293C19.5178 23.1108 19.2652 23.01 19.003 23.0123C18.7408 23.0146 18.49 23.1198 18.3046 23.3052C18.1192 23.4906 18.014 23.7414 18.0118 24.0036C18.0095 24.2658 18.1103 24.5184 18.2924 24.707L20.2924 26.707C20.3946 26.8066 20.5167 26.8835 20.6507 26.9326C20.7847 26.9817 20.9276 27.0018 21.0699 26.9917C21.2123 26.9816 21.3509 26.9415 21.4766 26.874C21.6024 26.8064 21.7124 26.7131 21.7994 26.6L24.7994 22.6C24.8782 22.4949 24.9356 22.3754 24.9681 22.2482C25.0007 22.121 25.008 21.9886 24.9894 21.8586C24.9708 21.7286 24.9268 21.6035 24.8599 21.4905C24.793 21.3775 24.7045 21.2788 24.5994 21.2C24.4944 21.1212 24.3748 21.0639 24.2476 21.0313C24.1204 20.9987 23.988 20.9915 23.858 21.0101C23.728 21.0286 23.6029 21.0726 23.4899 21.1395C23.3769 21.2064 23.2782 21.2949 23.1994 21.4Z"
        fill="#2EB67D"
      />
    </svg>
  );
};

export default ListIcon;
