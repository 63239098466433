const CustomerSupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none">
      <g clipPath="url(#clip0_566_2604)">
        <path
          d="M6.67814 6.01213C9.34602 3.34425 12.8931 1.875 16.666 1.875C19.5513 1.875 22.3408 2.75269 24.6867 4.37294L22.8756 4.55869L23.067 6.42387L27.942 5.92387L27.442 1.04887L25.5767 1.24019L25.7388 2.82087C23.084 0.991062 19.929 0 16.666 0C12.3923 0 8.37433 1.66431 5.35233 4.68631C2.33033 7.70831 0.666016 11.7262 0.666016 16C0.666016 19.1995 1.60752 22.2878 3.3887 24.9309L4.94358 23.8831C4.92202 23.8511 4.90108 23.8188 4.87977 23.7866C3.34964 21.4756 2.54102 18.786 2.54102 16C2.54102 12.2271 4.01027 8.68 6.67814 6.01213Z"
          fill="#2EB67D"
        />
        <path
          d="M29.9439 7.06909L28.389 8.1169C28.4106 8.1489 28.4315 8.18134 28.4528 8.21347C29.9829 10.5245 30.7916 13.214 30.7916 16C30.7916 19.773 29.3223 23.32 26.6544 25.9879C23.9866 28.6558 20.4396 30.125 16.6666 30.125C13.7813 30.125 10.9918 29.2473 8.64587 27.6271L10.4569 27.4413L10.2656 25.5762L5.39062 26.0762L5.89062 30.9512L7.75588 30.7598L7.59375 29.1792C10.2487 31.009 13.4036 32 16.6666 32C20.9403 32 24.9583 30.3357 27.9803 27.3137C31.0023 24.2917 32.6666 20.2738 32.6666 16C32.6666 12.8005 31.7251 9.71222 29.9439 7.06909Z"
          fill="#2EB67D"
        />
        <path
          d="M8.47681 17.9678L9.69056 16.8459C10.6101 16 10.7848 15.4299 10.7848 14.8046C10.7848 13.6093 9.76412 12.8461 8.28375 12.8461C7.01481 12.8461 6.1045 13.3794 5.61719 14.1886L6.96888 14.9426C7.22631 14.5288 7.64931 14.3173 8.11825 14.3173C8.66994 14.3173 8.94581 14.5656 8.94581 14.9794C8.94581 15.2461 8.87225 15.5403 8.35731 16.0184L5.94819 18.2713V19.4114H10.9595V17.9678L8.47681 17.9678Z"
          fill="#2EB67D"
        />
        <path
          d="M16.7452 18.1793H17.7015V16.7357H16.7452V15.6415H15.0257V16.7357H13.72L16.3406 12.9749H14.4556L11.5684 16.9931V18.1793H14.9705V19.4114H16.7452V18.1793Z"
          fill="#2EB67D"
        />
        <path
          d="M19.3567 20.3308L22.391 11.6691H20.8371L17.8027 20.3308H19.3567Z"
          fill="#2EB67D"
        />
        <path
          d="M23.9349 15.4115V14.4185H25.6267L23.4567 19.4114H25.4429L27.714 14.115V12.9749H22.3809V15.4115H23.9349Z"
          fill="#2EB67D"
        />
      </g>
      <defs>
        <clipPath id="clip0_566_2604">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.666016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomerSupportIcon;
