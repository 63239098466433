import { memo, useCallback, useState } from "react";
import CryptoJS from "crypto-js";
import "./desktop.scss";
import "./mobile.scss";
import { useNavigate } from "react-router-dom";
import AppModal from "components/AppModal";
import { HiXMark } from "react-icons/hi2";
import { RiErrorWarningFill } from "react-icons/ri";
const Footer: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    []
  );

  const generateHmacHash = (email: string, timestamp: number) => {
    const secretKey = "swimsynlandingpage";
    const message = `${email}:${timestamp}`;
    const hmac = CryptoJS.HmacSHA256(message, secretKey);
    return hmac.toString(CryptoJS.enc.Hex);
  };

  const handleSubmit = useCallback(async () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    const timestamp = Date.now();
    const hash = generateHmacHash(email, timestamp);
    try {
      const response = await fetch(
        `https://swim-sync-api.advancer.asia/api/v1/landing-pages/send-welcome-email-to-subscriber`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
            hash,
            timestamp,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result.data) {
        setOpenModalSuccess(true);
        setEmail("");
      } else {
        setOpenModalError(true);
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  }, [email]);

  const swimSyncFooterLinks = [
    {
      title: "Contact Us",
      subLinks: [
        { label: "Global Advancer Co., Ltd", link: "#", icon: null },
        {
          label:
            "Level 18, Park Ventures Ecoplex, 57 Wireless Road, Lumpini, Pathumwan, Bangkok, Thailand",
          link: "#",
          icon: null,
        },
        {
          label: (
            <>
              Phone: <a href="tel:+18645283434">+1 864 528 3434</a>
            </>
          ),
          link: "#",
          icon: null,
        },
        {
          label: (
            <>
              Email:
              <a href="mailto:hello@swimsync.app">hello@swimsync.app</a>
            </>
          ),
          link: "#",
          icon: null,
        },
      ],
    },
    {
      title: "Explore",
      subLinks: [
        {
          label: "Platform",
          link: "/feature/administrative-tools",
          icon: null,
        },
        { label: "Pricing", link: "/pricing", icon: null },
        { label: "About Us", link: "/about", icon: null },
        { label: "Contact", link: "/contact", icon: null },
      ],
    },
    {
      title: "Follow",
      subLinks: [
        {
          label: "Facebook",
          link: "#",
          icon: <img src="/svgs/facebook.svg" alt="logo" />,
        },
        {
          label: "Instagram",
          link: "#",
          icon: <img src="/svgs/instagram.svg" alt="logo" />,
        },
        {
          label: "X",
          link: "https://x.com/swimsyncapp",
          icon: <img src="/svgs/x.svg" alt="logo" />,
        },
        {
          label: "Youtube",
          link: "#",
          icon: <img src="/svgs/youtube.svg" alt="logo" />,
        },
      ],
    },
  ];

  return (
    <>
      {/* Success Modal */}
      <AppModal
        open={openModalSuccess}
        onClose={() => setOpenModalSuccess(false)}
      >
        <div className="receiveMailModal">
          <div className="header">
            <img src="/images/receive-mail.png" alt="" />
            <div
              className="loginModal-header__close"
              onClick={() => setOpenModalSuccess(false)}
            >
              <HiXMark size={24} />
            </div>
          </div>
          <div className="content">
            Thank you for subscribing! Check your inbox for your Splash of
            Welcome!
          </div>
        </div>
      </AppModal>

      {/* Error Modal */}

      <AppModal
        open={openModalError}
        onClose={() => setOpenModalSuccess(false)}
      >
        <div className="receiveMailModal">
          <div className="header">
            <RiErrorWarningFill size={54} className="icon" />
            <div
              className="loginModal-header__close"
              onClick={() => setOpenModalError(false)}
            >
              <HiXMark size={24} />
            </div>
          </div>
          <div className="content">{errorMessage}</div>
        </div>
      </AppModal>

      <div className="footer" id="contact">
        <div className="footer-content">
          <div className="logo">
            <img src="/svgs/logo-text-white.svg" alt="logo" />
          </div>

          <div className="linkWrap">
            {swimSyncFooterLinks.map((item, key) => (
              <div className="wrap container-footer" key={key}>
                <div className="tittle">{item?.title}</div>

                <div className="subWrap">
                  {item?.subLinks.map((subLink, key2) => (
                    <div className="wrap" key={key2}>
                      {subLink?.icon && subLink?.link !== "#" ? (
                        <a
                          href={subLink?.link}
                          className={`label ${
                            subLink?.link !== "#" ? "link" : ""
                          }`}
                        >
                          {subLink?.icon ? subLink?.icon : null}
                          {subLink?.label}
                        </a>
                      ) : (
                        <div
                          className={`label ${
                            subLink?.link !== "#" ? "link" : ""
                          }`}
                          onClick={() => navigate(subLink?.link)}
                        >
                          {subLink?.icon ? subLink?.icon : null}
                          {subLink?.label}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="wrap">
              <div className="tittle">Newsletter Signup</div>

              <div className="subWrap">
                <div className="wrap">
                  <div className="label">
                    Stay Updated with Swim School Management Tips!
                  </div>
                  <div className="label">
                    <div>
                      <div className="input__wrapper">
                        <input
                          type="email"
                          placeholder="Email Address"
                          onChange={handleEmailChange}
                          value={email}
                        />
                        <button className="btn" onClick={handleSubmit}>
                          Subscribe Now
                        </button>
                      </div>
                      {emailError && (
                        <div className="error-message">
                          Please enter a valid email address
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>Copyright © 2023. All Right Reserved</div>
          <div>
            <span onClick={() => navigate("/data-privacy")}>Data Privacy</span>{" "}
            <span onClick={() => navigate("/terms-and-conditions")}>
              Terms & Conditions
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(Footer);
